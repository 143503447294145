import { ENUMS } from '../enums';

export const KEY = [
	{
		key: 'keyId',
		description: '卡/账号 [长度1-20]',
		example: '',
		type: 'String',
	},
	{
		key: 'keyIdIN',
		description: '转入卡号/账户号',
		example: '',
		type: 'String',
	},
	{
		key: 'keyIdINType',
		description: '转入类型',
		example: '1',
		enum: ENUMS.keyIdTypeEnum,
		type: 'String',
	},
	{
		key: 'keyIdOUT',
		description: '转出卡映射ID/账号',
		example: '',
		type: 'String',
	},
];
