import { ACCOUNT } from './account';
import { BAKONG } from './bakong';
import { CARD_LIMIT } from './card-limit';
import { CARD } from './card';
import { CUST_INFO } from './cust-info';
import { DOCUMENT } from './document';
import { INSTITUTE } from './institute';
import { KEY } from './key';
import { MEMBER } from './member';
import { MERCHANT } from './merchant';
import { MOBILE } from './mobile';
import { NATIONALITY } from './nationality';
import { OPERATOR } from './operator';
import { OTHERS } from './others';
import { PAYMENT } from './payment';
import { PIN } from './pin';
import { QRCODE } from './qrcode';
import { QUERY } from './query';
import { STATUS } from './status';
import { TERMINAL } from './terminal';
import { TOP_UP } from './top-up';
import { TRANSACTION } from './transaction';
import { TRANSFER } from './transfer';
import { PARTNER } from './partner';

export const DEFAULT_ATTRIBUTE = {
	key: '',
	description: 'Attribute Not Available!',
	example: '',
	type: 'Attribute Not Available!',
};

export const ATTRIBUTES = [
	...ACCOUNT,
	...BAKONG,
	...CARD_LIMIT,
	...CARD,
	...CUST_INFO,
	...DOCUMENT,
	...INSTITUTE,
	...KEY,
	...MEMBER,
	...MERCHANT,
	...MOBILE,
	...NATIONALITY,
	...OPERATOR,
	...OTHERS,
	...PAYMENT,
	...PIN,
	...QRCODE,
	...QUERY,
	...STATUS,
	...TERMINAL,
	...TOP_UP,
	...TRANSACTION,
	...TRANSFER,
	...PARTNER,
];
