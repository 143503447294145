export const MERCHANT = [
	{
		key: 'merchantId',
		description: '商家ID',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantID',
		description: '商家ID',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantNm',
		description: '商家名称',
		example: '',
		type: 'String',
	},
	{
		key: 'mechantCode',
		description: '商家类型码',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantCity',
		description: '商户城市',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantCategoryCode',
		description: '商户类别代码',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantName',
		description: '商户名称',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantType',
		description: '商户类型',
		example: '',
		type: 'String',
	},
	{
		key: 'merType',
		description: '商户类型',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantLocation',
		description: '商户地址',
		example: '',
		type: 'String',
	},
];
