<template>
	<div :class="`info-alert ${type + '-alert'}`">
		<div class="alert-text">
			<InfoIcon />
			<p>{{ text }}</p>
		</div>
		<CloseIcon class="close-alert" @click="onCloseAlert" />
	</div>
</template>

<script>
import InfoIcon from '../icons/InfoIcon.vue';
import CloseIcon from '../icons/CloseIcon.vue';

export default {
	name: 'InfoAlert',
	props: {
		type: String,
		text: String,
	},
	components: {
		InfoIcon,
		CloseIcon,
	},
	methods: {
		onCloseAlert() {
			this.$emit('closeAlert');
		},
	},
};
</script>
