// import { ENUMS } from '../enums';
// import { VALUES } from '../values';

export const PARTNER = [
	{
		key: 'partnerId',
		description: '合作方ID',
		example: '',
		type: 'String',
	},
	{
		key: 'pwdEnc',
		description: '密码',
		encrypted: true,
		example: '',
		type: 'String',
	},
	{
		key: 'userNameEnc',
		description: '姓名',
		encrypted: true,
		example: '',
		type: 'String',
	},
];
