<template>
	<p class="jump-to-search-box-dialog" v-if="currentLanguage == 'en'">
		Press<span class="slash">/</span>to jump to the search box.
	</p>
	<p class="jump-to-search-box-dialog" v-if="currentLanguage == 'cn'">
		按<span class="slash">/</span>跳转到搜索框。
	</p>
</template>

<script>
export default {
	name: 'JumpToSearchBoxDialog',
	computed: {
		currentLanguage() {
			return this.$route.query.lang || 'en';
		},
	},
};
</script>
