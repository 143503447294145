import { ENUMS } from '../enums';

export const QRCODE = [
  {
		key: 'qrCode',
		description: '二维码',
		example: '',
		type: 'String',
	},
	{
		key: 'receivingQr',
		description: '二维码',
		example: '',
		type: 'String',
	},
  {
		key: 'qrcodeCategory',
		description: '二维码分别',
		example: '',
		enum: ENUMS.qrcodeCategoryEnum,
		type: 'String',
	},
	{
		key: 'qrcodeState',
		description: '二维码类型',
		example: '',
		enum: ENUMS.qrcodeStateEnum,
		type: 'String',
	},
  {
		key: 'payloadFormatIndicator',
		description: '定义二维码模板的版本和标识, 长度, 值的约定',
		example: '01',
		enum: ENUMS.payloadFormatIndicator,
		type: 'String',
	},
	{
		key: 'pointofInitiationMethod',
		description: '识别通信技术 [二维码] 数据是静态的还是动态的',
		example: '12',
		enum: ENUMS.pointofInitiationMethodEnum,
		type: 'String',
	},
  {
		key: 'barcodeCpqrcPayload',
		description: '条形付款码 [境内]',
		example: '',
		type: 'String',
	},
	{
		key: 'barcodeCpqrcPayloadtxnID',
		description: '条形付款码流水号',
		example: '',
		type: 'String',
	},
  {
		key: 'emvCpqrcPayload',
		description: '付款码 [境外]',
		example: '',
		type: 'String',
	},
	{
		key: 'emvCpqrcPayloadtxnID',
		description: '付款码流水号',
		example: '',
		type: 'String',
	},
  {
		key: 'receivingQR',
		description: '收款码',
		example: '',
		type: 'String',
	},
];