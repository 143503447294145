import { ENUMS } from '../enums';

export const CUST_INFO = [
	{
		key: 'custId',
		description: 'Customer ID (15 Digits, Unique to Every User)',
		example: '000000000010126',
		type: 'String',
	},
	{
		key: 'custIdIN',
		description: "Receiver's Customer ID (15 Digits, Unique to Every User)",
		example: '000000000010126',
		type: 'String',
	},
	{
		key: 'custIdOUT',
		description: "Sender's Customer ID (15 Digits, Unique to Every User)",
		example: '000000000010126',
		type: 'String',
	},
	{
		key: 'nameEnc',
		description:
			'Full Name (Length Up to 50, All Capital Letters, Last Name - First Name, Example: DOE JOHN)',
		example:
			'Rw8kE7nEZbJRNUIJOAwBz9Ijfc3bUBSSPaUPKQq5KAAFoBY69uXuDC33bwtOKVK8ivWzKL+SIWgJtGR6xfjaLEmsgJmoYnV2MqNwV+ypCwWlP6KKn+guvXVc/cq/+5Wa42o1MKIRlyKyODsO8ncFuE1cCjL+xE80wgbN1dC5o40=',
		encrypted: true,
		type: 'String',
	},
	{
		key: 'khNameEnc',
		description: 'Khmer Name (Last Name + " " + First Name)',
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'nameDec',
		description:
			'Decrypted Full Name (Length Up to 50, All Capital Letters, Last Name - First Name, Example: DOE JOHN)',
		example: '',
		type: 'String',
	},
	{
		key: 'addr',
		description: 'Address (Length Up to 200)',
		example: '',
		type: 'String',
	},
	{
		key: 'email',
		description: 'Email Address',
		example: 'mail@bongloy.com',
		type: 'String',
	},
	{
		key: 'headPicUrlFlag',
		description: 'Uploaded Head Photo Flag',
		example: 'Y',
		enum: ENUMS.flagEnum,
		type: 'String',
	},
	{
		key: 'idPhotos',
		description: 'Photo of Documents',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: ['base64Data', 'originalFileName'],
	},
	{
		key: 'headPhotos',
		description: 'Head Photo of User',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: ['base64Data', 'originalFileName'],
	},
	{
		key: 'base64Data',
		description: 'Base64 Data of Image',
		example: '',
		type: 'String',
	},
	{
		key: 'originalFileName',
		description: 'Original File Name of Image',
		example: '',
		type: 'String',
	},
	{
		key: 'dob',
		description: 'Date of Birth (yyyyMMdd)',
		example: '20221231',
		type: 'String',
	},
	{
		key: 'endDt',
		description: 'End Date (Format: yyMM)',
		example: '20220911',
		type: 'String',
	},
	{
		key: 'endDtOUT',
		description: "Sender's Card Expiry Date",
		example: '',
		type: 'String',
	},
	{
		key: 'startDt',
		description: 'Card Issued Date',
		example: '',
		type: 'String',
	},
	{
		key: 'prdtNo',
		description: 'Account Currency Type',
		example: '0003',
		enum: ENUMS.currencyEnum,
		type: 'String',
	},
	{
		key: 'prdtNoIN',
		description: "Receiver's Account Currency Type",
		example: '0003',
		enum: ENUMS.currencyEnum,
		type: 'String',
	},
	{
		key: 'prdtNoOUT',
		description: "Sender's Account Currency Type",
		example: '0003',
		enum: ENUMS.currencyEnum,
		type: 'String',
	},
	{
		key: 'sex',
		description: 'Sex',
		example: '',
		enum: ENUMS.sexEnum,
		type: 'String',
	},
];
