export const EKYC = {
	include: [
		// virtual card
		'1101',
		'1102',
		'1103',
		'1104',

		// physical card

		// verification
		'2101',

		// query cardholder info
		'2211',
		'2221',
		'2241',
		'2242',

		// modify cardholder info

		// card configuration
		'3103',
		'3104',
		'3105',
		'3106',
		'3107',

		// internal transfer
		'4101',
		'4102',

		// bakong transaction
		'4231',
		'4253',

		// umps
		'4313',

		// umts
		// others
		// notificaiton
	],
};
