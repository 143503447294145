import { ENUMS } from '../enums';

export const PAYMENT = [
	{
		key: 'payeeNameEnc',
		description: '收款人姓名 [明文最大30位]',
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payeePanEnc',
		description: '收款人pan [明文最大19位]',
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerAccountEnc',
		description: '付款人pan,token或者账号 [明文最大19位]',
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerIdNoEnc',
		description: '证件号码 [明文最大20位]',
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerExtCardId',
		description: "付款人卡映射ID [每个卡都是独特]",
		example: '',
		type: 'String',
	},
	{
		key: 'payerIdType',
		description: '证件类型',
		example: '',
		enum: ENUMS.documentTypeEnum,
		type: 'String',
	},
	{
		key: 'payerNameEnc',
		description: '付款人姓名 [明文最大30位]',
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerNationality',
		description: '付款人国籍[定长3位]',
		example: '',
		type: 'String',
	},
];
