import { ENUMS } from '../enums';

export const DOCUMENT = [
	{
		key: 'pidType',
		description: 'Type of Document',
		example: '1',
		enum: ENUMS.documentTypeEnum,
		type: 'String',
	},
	{
		key: 'pidNoEnc',
		description: 'Document Number',
		example:
			'mSlDtey0+Pv7PVQ+JaQZbX/WkA2q2cPUfNP5GI7npz7fQ7uYGVWfRyOp/3wkSaxB1uQ+aD/VlQlIx03uctiwVGQbeLMLfj964/bvPSg06Ub7u/FjZCH3tLnBPf8p0QsGitUK52T5DNuFgORbS+Jhal96NEsJaR2bVVYh6rkAf0T/ggb2A3nXNOzAkLTzlBDrbTSXqW7nwTAyy+x0Su4rXDYdoK48opV9zmm63p4mqMUQFpje7HKyWTtCZMbQDJdG83B0fB1mjZyHwrN9zVxu6WoCU/0+7IuvJZvq4dpCZm1qV3UbT10clhg36WV90wbxQ7GAtSpWab6THgeo/sHA9w==',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'pidNoUrlFlag',
		description: 'Uploaded Document Photo Flag',
		example: 'Y',
		enum: ENUMS.flagEnum,
		type: 'String',
	},
];
