import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { sync } from 'vuex-router-sync';

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	FontAwesomeIcon,
	FontAwesomeLayers,
	FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import {
	faUser,
	faCaretDown,
	faMagnifyingGlass,
	faBars,
	faXmark,
} from '@fortawesome/free-solid-svg-icons';

sync(store, router);

library.add(faUser, faBuilding, faCaretDown, faMagnifyingGlass, faBars, faXmark);

createApp(App)
	.use(store)
	.use(router)
	.component('font-awesome-icon', FontAwesomeIcon)
	.component('font-awesome-layers', FontAwesomeLayers)
	.component('font-awesome-layer-text', FontAwesomeLayersText)
	.mount('#app');
