import { HTTP_METHODS, TAG } from '@/const';

export const UMTS = [
	{
		id: '4401',
		title: 'Money Express',
		titleCN: '国际汇款',
		endpoint: '/app/internationalRemittance',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		attributes: [
			'businessIndication',
			'custId',
			'externalTxnRefId',
			'fundSource',
			'payeeNameEnc',
			'payeePanEnc',
			'payerExtCardId',
			'txnAmt',
		],
		optionalAttributes: ['externalTxnRefId'],
		respAttributes: [
			'cardIdIN4',
			'cardIdOUT4',
			'currAvailAtOUT',
			'currBalAtOUT',
			'externalTxnRefId',
			'fee',
			'intTxnRefId',
			'intTxnSeqId',
			'lockAtOUT',
			'note',
			'orderNo',
			'originalTxnAmt',
			'originalTxnCurrency',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'state',
			'txnAt',
			'txnDt',
			'txnTm',
			'txnType',
		],
	},
	{
		id: '4402',
		title: 'Money Express Order Inquiry',
		titleCN: '国际汇款订单查询',
		endpoint: '/app/internationalRemittanceOrderQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		attributes: ['orderNo'],
		respAttributes: ['origResponseCode', 'origResponseMsg'],
	},
];
