import { ENUMS } from '../enums';
import { VALUES } from '../values';

export const BAKONG = [
	{
		key: 'assetId',
		description: 'Currency Type for Bakong (USD/KHR)',
		example: 'KHR',
		type: 'String',
	},
	{
		key: 'txnHash',
		description: 'Bakong Transaction Hash',
		example: '',
		type: 'String',
	},
	{
		key: 'shortHash',
		description: 'Short Bakong Transaction Hash (First 8 Characters of txnHash)',
		example: '',
		type: 'String',
	},
	{
		key: 'transactionHash',
		description: 'Bakong Transaction Hash',
		example: '',
		type: 'String',
	},
	{
		key: 'destAccountId',
		description: 'ID of The Bakong Account Destination',
		example: '',
		type: 'String',
	},
	{
		key: 'destAccountName',
		description: 'Name of The Bakong Account Destination',
		example: '',
		type: 'String',
	},
	{
		key: 'destBankCode',
		description: 'Code of The Bakong Bank Destination',
		example: '',
		type: 'String',
	},
	{
		key: 'destBankName',
		description: 'Name of The Bakong Bank Destination',
		example: '',
		type: 'String',
	},
	{
		key: 'bakongType',
		description: 'Bakong Type',
		example: '',
		enum: ENUMS.bakongTypeEnum,
		type: 'String',
	},
	{
		key: 'bankName',
		description: 'Bank Name',
		example: '',
		type: 'String',
	},
	{
		key: 'participantCode',
		description: 'Bank Code',
		example: '',
		type: 'String',
	},
	{
		key: 'acquiringBank',
		description: 'Acquiring Bank',
		example: 'Bongloy Payments PLC',
		type: 'String',
	},
	{
		key: 'bakongAccountID',
		description: 'Bakong Account ID',
		example: '',
		type: 'String',
	},
	{
		key: 'bakongAcctId',
		description: 'Bakong Account ID',
		example: 'username@bong',
		type: 'String',
	},
	{
		key: 'bankCodeOUT',
		description: 'Outgoing Bank Code',
		example: '',
		type: 'String',
	},
	{
		key: 'bankNmOUT',
		description: 'Outgoing Bank Name',
		example: '',
		type: 'String',
	},
	{
		key: 'destAcct',
		description: 'ID of Destination Bakong Account',
		example: '',
		type: 'String',
	},
	{
		key: 'destAcctNm',
		description: 'Name of Destination Bakong Account',
		example: '',
		type: 'String',
	},
	{
		key: 'destBankNm',
		description: 'Name of Destination Bakong Bank',
		example: '',
		type: 'String',
	},
	{
		key: 'sourceAcct',
		description: 'ID of the Bakong Account Source',
		example: '',
		type: 'String',
	},
	{
		key: 'sourceAcctNm',
		description: 'Name of the Bakong Account Source',
		example: '',
		type: 'String',
	},
	{
		key: 'sourceBankCode',
		description: 'Code of the Bakong Bank Source',
		example: '',
		type: 'String',
	},
	{
		key: 'sourceBankNm',
		description: 'Name of the Bakong Bank Source',
		example: '',
		type: 'String',
	},
	{
		key: 'srcBankName',
		description: 'Name of the Bakong Bank Source',
		example: '',
		type: 'String',
	},
	{
		key: 'bankList',
		description: 'Bank List',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: ['accountId', 'bin', 'logoUuid', 'participantCode', 'publicOperations'],
	},
	{
		key: 'bin',
		description: 'Bank Name',
		example: '',
		type: 'String',
	},
	{
		key: 'logoUuid',
		description: 'Logo UUID',
		example: '',
		type: 'String',
	},
	{
		key: 'publicOperations',
		description: 'Public Operations',
		example: '',
		value: VALUES.publicOperationsValue,
		type: 'Array',
		attributes: [],
	},
	{
		key: 'bkupiFee',
		description: 'Bakong UPI Fee',
		example: '',
		type: 'String',
	},
	{
		key: 'bkupiFeeCurrency',
		description: 'Bakong UPI Fee Currency',
		example: '',
		type: 'String',
	},
	{
		key: 'bkUpiFee',
		description: 'Bakong UPI Fee',
		example: '',
		type: 'String',
	},
	{
		key: 'bkUpiFeeCurrency',
		description: 'Bakong UPI Fee Currency',
		example: '',
		type: 'String',
	},
	{
		key: 'settleWithBakongAmt',
		description: 'Bakong Settled Amount Including Fee, and Exchange Rate Fee',
		example: '',
		type: 'String',
	},
	{
		key: 'settleWithBakongCurrency',
		description: 'Bakong Settled Currency',
		example: '',
		type: 'String',
	},
	{
		key: 'bkupiRate',
		description: 'Bakong UPI Rate',
		example: '',
		type: 'String',
	},
	{
		key: 'bkupiRateAsOfDate',
		description: 'Bakong Rate as of Date',
		example: '',
		type: 'String',
	},
	{
		key: 'bkupiRateCurrency',
		description: 'Bakong UPI Rate Currency',
		example: '',
		type: 'String',
	},
];
