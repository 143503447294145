<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		class="icon icon-tabler icon-tabler-table-options"
		width="26"
		height="26"
		viewBox="0 0 24 24"
		stroke-width="2"
		stroke="currentColor"
		fill="none"
		stroke-linecap="round"
		stroke-linejoin="round">
		<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
		<path d="M4 12v-6a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-6"></path>
		<path d="M4 10h16"></path>
		<path d="M10 4v9"></path>
		<circle cx="5.281" cy="18.5" r="2"></circle>
		<path d="M5.281 15v1.5"></path>
		<path d="M5.281 20.5v1.5"></path>
		<path d="M8.312 16.75l-1.299 .75"></path>
		<path d="M3.55 19.5l-1.3 .75"></path>
		<path d="M2.25 16.75l1.3 .75"></path>
		<path d="M7.013 19.5l1.3 .75"></path>
	</svg>
</template>

<script>
export default {
	name: 'CardConfigIcon',
};
</script>
