export const QUERY = [
	{
		key: 'pageNum',
		description: '页码 [大小1-100000000]',
		example: '1',
		type: 'String',
	},
	{
		key: 'pageSize',
		description: '每页条数 [大小1-100000000]',
		example: '20',
		type: 'String',
	},
	{
		key: 'totalCount',
		description: '总数',
		example: '',
		type: 'String',
	},
];
