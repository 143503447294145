export const TRANSFER = [
	{
		key: 'transferDate',
		description: '转账日期 [yyyyMMdd], topUpType=01必填',
		example: '',
		type: 'String',
	},
	{
		key: 'transferNo',
		description: '转账凭证编号, topUpType=01必填 [最大长度64]',
		example: '',
		type: 'String',
	},
	{
		key: 'transferTime',
		description: '转账时间 [HHmmss], topUpType=01必填',
		example: '',
		type: 'String',
	},
	{
		key: 'transfereeInfo',
		description: '收款人账户信息, topUpType=01必填 [最大长度256]',
		example: '',
		type: 'String',
	},
	{
		key: 'transfererInfo',
		description: '付款人账户信息, topUpType=01必填 [最大长度256]',
		example: '',
		type: 'String',
	},
];
