import { ENUMS } from '../enums';
import { VALUES } from '../values';

export const STATUS = [
	{
		key: 'acctSta',
		description: 'Account Status',
		example: '00',
		enum: ENUMS.accountStatusEnum,
		type: 'String',
	},
	{
		key: 'accountStatus',
		description: 'Account Status',
		example: '',
		type: 'String',
	},
	{
		key: 'cardSta',
		description: 'Card Status',
		example: '0',
		enum: ENUMS.cardStatusEnum,
		type: 'String',
	},
	{
		key: 'txnStaCd',
		description: 'Transaction Status Code',
		example: '2',
		enum: ENUMS.transactionStatusCodeEnum,
		type: 'String',
	},
	{
		key: 'sta',
		description: 'Application Status',
		example: '01',
		type: 'String',
		enum: ENUMS.applicationStatusEnum,
	},
	{
		key: 'allocateStat',
		description: 'Allocated Status',
		example: '1',
		enum: ENUMS.allocatedStatusEnum,
		type: 'String',
	},
	{
		key: 'paymentStatus',
		description: 'Payment Status',
		example: '',
		type: 'String',
	},
	{
		key: 'state',
		description: 'Order Status',
		example: '0',
		enum: ENUMS.stateEnum,
		type: 'String',
	},
	{
		key: 'kycStatus',
		description: 'KYC Status',
		example: '',
		type: 'String',
	},
	{
		key: 'upiState',
		description: 'Union Pay Mobile Service Activation Status',
		example: '0',
		enum: ENUMS.upiStateEnum,
		type: 'String',
	},
	{
		key: 'keyStatus',
		description: 'Account/Card Status',
		example: '',
		enum: ENUMS.keyStatusEnum,
		type: 'String',
	},
	{
		key: 'status',
		description: 'Transaction Status',
		example: '',
		enum: ENUMS.transactionStatusEnum,
		value: VALUES.preauthorizationStatusValue,
		type: 'String',
	},
	{
		key: 'curCardStatus',
		description: 'Current Card Status',
		example: '0',
		enum: ENUMS.cardStatusEnum,
		type: 'String',
	},
	{
		key: 'prevCardStatus',
		description: 'Previous Card Status',
		example: '1',
		enum: ENUMS.cardStatusEnum,
		type: 'String',
	},
];
