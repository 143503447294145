export const QUERY = [
	{
		key: 'pageNum',
		description: 'Page Number (Pagination)',
		example: '1',
		type: 'String',
	},
	{
		key: 'pageSize',
		description: 'Page Size (Pagination)',
		example: '20',
		type: 'String',
	},
	{
		key: 'totalCount',
		description: 'Total Count',
		example: '',
		type: 'String',
	},
];
