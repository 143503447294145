// import { ENUMS } from '../enums';
// import { VALUES } from '../values';

export const PARTNER = [
	{
		key: 'partnerId',
		description: 'Partner ID',
		example: '',
		type: 'String',
	},
  {
		key: 'pwdEnc',
		description: 'Password',
    encrypted: true,
		example: '',
		type: 'String',
	},
  {
		key: 'userNameEnc',
		description: 'Username',
    encrypted: true,
		example: '',
		type: 'String',
	}
]