<template>
	<div></div>
</template>

<script>
export default {
	name: 'RedirectPage',
	created() {
		this.$router.push({ path: `${this.$route.meta.redirect}` });
	},
};
</script>
