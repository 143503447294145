export const INSTITUTE = [
	{
		key: 'instituteId',
		description: '机构ID',
		example: '',
		type: 'String',
	},
	{
		key: 'instituteIdIN',
		description: '转入机构ID',
		example: '',
		type: 'String',
	},
	{
		key: 'instituteIdOUT',
		description: '转出机构ID',
		example: '',
		type: 'String',
	},
	{
		key: 'instituteNm',
		description: '机构名称',
		example: '',
		type: 'String',
	},
	{
		key: 'instituteNmIN',
		description: '转入机构名称',
		example: '',
		type: 'String',
	},
	{
		key: 'instituteNmOUT',
		description: '转出机构名称',
		example: '',
		type: 'String',
	},
	{
		key: 'orgId',
		description: '机构ID',
		example: '',
		type: 'String',
	},
	{
		key: 'organizationName',
		description: '机构名称',
		example: '',
		type: 'String',
	},
];
