import { ENUMS } from "../enums";

export const TOP_UP = [
  {
		key: 'amt',
		description: 'Amount',
		example: '',
		type: 'String',
	},
  {
		key: 'elecAppNo',
		description: 'Electronic Tracking Number',
		example: '',
		type: 'String',
	},
  {
		key: 'topUpType',
		description: 'Top Up Type',
		example: '00',
		enum: ENUMS.topUpTypeEnum,
		type: 'String',
	},
];