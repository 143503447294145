import { HTTP_METHODS, TAG } from '@/const';

export const PHYSICAL_CARD = [
	{
		id: '1201',
		title: 'Request Physical Card (New User)',
		titleCN: '实体卡开卡申请 [新客户]',
		endpoint: '/app/physicalCardOpen',
		method: HTTP_METHODS.POST,
		tag: TAG.PHYSICAL_CARD,
		note: 'Please make sure the value of khNameEnc is in Khmer Letter before you encrypt.',
		noteCN: '加密前请确保 khNameEnc 的值是高棉字母。',
		attributes: [
			'addr',
			'appliOpr',
			'brandId',
			'dob',
			'email',
			'headPhotos',
			'idPhotos',
			'khNameEnc',
			'memberEnrolledDate',
			'memberId',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'organizationName',
			'pidNoEnc',
			'pidType',
			'referralCode',
			'sex',
			'tel',
			'totNum',
			'zip',
		],
		optionalAttributes: [
			'dob',
			'email',
			'khNameEnc',
			'memberEnrolledDate',
			'memberId',
			'organizationName',
			'referralCode',
			'sex',
			'tel',
			'totNum',
			'zip',
		],
		respAttributes: ['physicalAppId'],
	},
	{
		id: '1202',
		title: 'Request Additional Physical Card (Old User)',
		titleCN: '实体卡开卡申请 [老客户]',
		endpoint: '/app/physicalCardAdd',
		method: HTTP_METHODS.POST,
		tag: TAG.PHYSICAL_CARD,
		attributes: [
			'appliOpr',
			'brandId',
			'custId',
			'mobile',
			'nameEnc',
			'pidNoEnc',
			'pidType',
			'referralCode',
			'totNum',
		],
		optionalAttributes: ['referralCode', 'totNum'],
		respAttributes: ['physicalAppId'],
	},
	{
		id: '1203',
		title: 'Query Requesting Physical Card Progress',
		titleCN: '实体卡申请单详情',
		endpoint: '/app/physicalCardAppliDetail',
		method: HTTP_METHODS.POST,
		tag: TAG.PHYSICAL_CARD,
		attributes: ['physicalAppId'],
		respAttributes: ['brandId', 'custId', 'extCardIds', 'note', 'prdtNo', 'sta'],
	},
	{
		id: '1204S',
		title: 'Query Physical Card Sensitive Data',
		titleCN: '查看实体卡敏感信息',
		endpoint: '/app/physicalCardSensQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.PHYSICAL_CARD,
		attributes: ['custId', 'extCardId'],
		respAttributes: ['brandId', 'cardIdEnc', 'cardSta', 'cvn2', 'endDt', 'startDt'],
	},
	{
		id: '1205S',
		title: 'Physical Card Application Approval',
		titleCN: '实体卡审核',
		endpoint: '/app/physicalCheck',
		method: HTTP_METHODS.POST,
		tag: TAG.PHYSICAL_CARD,
		attributes: ['allocateStat', 'checkOpr', 'note', 'physicalAppId'],
		respAttributes: ['physicalAppId'],
	},
	{
		id: '1206S',
		title: 'Request Physical/Virtual Card (New User) (No Audit)',
		titleCN: '新客户开卡申请 [免审]',
		endpoint: '/app/requestOpenCardNoAudit',
		method: HTTP_METHODS.POST,
		tag: TAG.PHYSICAL_CARD,
		note: 'Please make sure the value of khNameEnc is in Khmer Letter before you encrypt.',
		noteCN: '加密前请确保 khNameEnc 的值是高棉字母。',
		attributes: [
			'addr',
			'appliOpr',
			'brandId',
			'checkOpr',
			'dob',
			'email',
			'extCustId',
			'headPhotos',
			'idPhotos',
			'khNameEnc',
			'memberEnrolledDate',
			'memberId',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'note',
			'organizationName',
			'pidNoEnc',
			'pidType',
			'referralCode',
			'sex',
			'tel',
			'totNum',
			'type',
			'zip',
		],
		optionalAttributes: [
			'dob',
			'email',
			'extCustId',
			'khNameEnc',
			'memberEnrolledDate',
			'memberId',
			'organizationName',
			'referralCode',
			'sex',
			'tel',
			'totNum',
			'type',
			'zip',
		],
		respAttributes: ['appId', 'cardIdEnc', 'custId', 'extCardId'],
	},
	{
		id: '1206SB',
		title: 'Request Virtual Card (New User of Other Banks) (No Audit)',
		titleCN: '其他银行虚拟卡开卡 [免审]',
		endpoint: '/app/onboardingUPI',
		method: HTTP_METHODS.POST,
		tag: TAG.VIRTUAL_CARD,
		note: 'Please make sure the value of khNameEnc is in Khmer Letter before you encrypt.',
		noteCN: '加密前请确保 khNameEnc 的值是高棉字母。',
		attributes: [
			'addr',
			'brandId',
			'dob',
			'email',
			'headPhotos',
			'idPhotos',
			'khNameEnc',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'note',
			'pidNoEnc',
			'pidType',
			'sex',
		],
		optionalAttributes: ['dob', 'email', 'khNameEnc', 'sex'],
		respAttributes: ['appId', 'mappingId'],
	},
];
