<template>
	<div id="app">
		<router-view />
	</div>
</template>

<style lang="scss">
@import '@/assets/stylesheets/app.scss';
@import '@/assets/stylesheets/client/client.scss';
</style>
