const attributes = {
	namespaced: true,
	state: {
		attributes: [],
	},
	mutations: {},
	actions: {},
	getters: {
		// getAttributeByKey: (state) => (key) => {
		// 	const attri = state.attributes.find((e) => e.key == key);
		// 	if (!attri) {
		// 		return {
		// 			key: key,
		// 			description: 'Attribute missing!',
		// 			example: 'Attribute missing!',
		// 			type: 'Attribute missing!',
		// 		};
		// 	}
		// 	return attri;
		// },
		// getAttributeExampleByKey: (state) => (key) => {
		// 	const attri = state.attributes.find((e) => e.key == key);
		// 	if (!attri) {
		// 		return { example: '' };
		// 	}
		// 	return { example: attri.example, exampleJSON: attri.exampleJSON };
		// },
	},
};

export default attributes;
