export const PIN = [
	{
		key: 'pinEnc',
		description: '卡ATM密码 [6 位数]',
		example:
			'RcMKDQe9vijerZMwMb4ozt0jlfBBsXWNvKld9159rVHRcRx0LQRmx6nrPpDtgpnu4Dw/5O4PH0nCZF+BDAjLjvbTmL2p9Tf5R0hxu9SGd1LcWOxuGTC6akthXR/2QAAqO5jPpomcS3RQTl/yEBWQkqrTyskJenyy9ogBAw2FUfui9tK/1Y+My6w8dAE4YIiuZ6b5bYhrnixqY3wVooQBJkneOIOV1T4ZqGSpL2Z4UpDi2eqPphPK2T8Xuth/keQUhGxcPrtqF2ui0mWEoqoHO2k5OXi7XWKBCRO+mODrrcDO+5HVKao+FkduPgr8AFtJAcQBOeGTbgYHDOlalt8HNg==',
		encrypted: true,
		type: 'String',
	},
	{
		key: 'newPinEnc',
		description: '卡ATM新密码 [6位数]',
		example:
			'RcMKDQe9vijerZMwMb4ozt0jlfBBsXWNvKld9159rVHRcRx0LQRmx6nrPpDtgpnu4Dw/5O4PH0nCZF+BDAjLjvbTmL2p9Tf5R0hxu9SGd1LcWOxuGTC6akthXR/2QAAqO5jPpomcS3RQTl/yEBWQkqrTyskJenyy9ogBAw2FUfui9tK/1Y+My6w8dAE4YIiuZ6b5bYhrnixqY3wVooQBJkneOIOV1T4ZqGSpL2Z4UpDi2eqPphPK2T8Xuth/keQUhGxcPrtqF2ui0mWEoqoHO2k5OXi7XWKBCRO+mODrrcDO+5HVKao+FkduPgr8AFtJAcQBOeGTbgYHDOlalt8HNg==',
		encrypted: true,
		type: 'String',
	},
];
