<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		class="icon icon-tabler icon-tabler-bell-ringing"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		stroke-width="2"
		stroke="currentColor"
		fill="none"
		stroke-linecap="round"
		stroke-linejoin="round">
		<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
		<path
			d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
		<path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
		<path d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727"></path>
		<path d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727"></path>
	</svg>
</template>

<script>
export default {
	name: 'NotificationIcon',
};
</script>
