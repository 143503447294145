import endpoints from './endpoints';
import attributes from './attributes';

const clientStore = {
	namespaced: true,
	modules: {
		endpoints,
		attributes,
	},
};

export default clientStore;
