export const MEMBER = [
	{
		key: 'memberId',
		description: 'Member ID',
		example: '',
		type: 'String',
	},
	{
		key: 'memberEnrolledDate',
		description: 'Member Enrolled Date (yyyyMMdd)',
		example: '20221231',
		type: 'String',
	},
];
