export const INSTITUTE = [
	{
		key: 'instituteId',
		description: 'Institute ID',
		example: '',
		type: 'String',
	},
	{
		key: 'instituteIdIN',
		description: 'Institute ID that Transfers In',
		example: '',
		type: 'String',
	},
	{
		key: 'instituteIdOUT',
		description: 'Institute ID that Transfers Out',
		example: '',
		type: 'String',
	},
	{
		key: 'instituteNm',
		description: 'Institute Name',
		example: '',
		type: 'String',
	},
	{
		key: 'instituteNmIN',
		description: 'Institute Name that Transfers In',
		example: '',
		type: 'String',
	},
	{
		key: 'instituteNmOUT',
		description: 'Institute Name that Transfers Out',
		example: '',
		type: 'String',
	},
	{
		key: 'orgId',
		description: 'Organization ID',
		example: '',
		type: 'String',
	},
	{
		key: 'organizationName',
		description: 'Organization Name',
		example: '',
		type: 'String',
	},
];
