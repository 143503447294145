import { HTTP_METHODS, TAG } from '@/const';

export const MODIFY_CARDHOLDER_INFO = [
	{
		id: '2301',
		title: "Modify Customer's Phone Number",
		titleCN: '客户资料修改手机号',
		endpoint: '/app/custInfoModifyCell',
		method: HTTP_METHODS.POST,
		tag: TAG.MODIFY_CARDHOLDER_INFO,
		attributes: ['appliOpr', 'checkOpr', 'custId', 'mobile'],
		respAttributes: [],
	},
	{
		id: '2302',
		title: 'Customer Information Request Form Details',
		titleCN: '客户资料申请单详情',
		endpoint: '/app/custInfoAppliDetail',
		method: HTTP_METHODS.POST,
		tag: TAG.MODIFY_CARDHOLDER_INFO,
		attributes: ['appId'],
		respAttributes: [
			'addr',
			'appId',
			'email',
			'headPicUrlFlag',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'note',
			'pidNoEnc',
			'pidNoUrlFlag',
			'pidType',
			'sex',
			'sta',
			'tel',
			'zip',
		],
	},
];
