import { INSTITUTE_LIST } from '@/const';
import EndpointListingPage from '../../pages/client/EndpointListingPage.vue';

const instituteRoutes = Object.keys(INSTITUTE_LIST).map((e) => {
	return {
		path: `${e}`,
		name: `${e}-endpoint-listing-page`,
		component: EndpointListingPage,
		meta: INSTITUTE_LIST[e],
	};
});

const documentationRouter = [
	{
		path: 'internal',
		name: 'endpoint-listing-page',
		component: EndpointListingPage,
	},
	...instituteRoutes,
];

export default documentationRouter;
