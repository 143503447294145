import { ENUMS } from "../enums";

export const TOP_UP = [
  {
		key: 'amt',
		description: '金额',
		example: '',
		type: 'String',
	},
  {
		key: 'elecAppNo',
		description: '电子单号',
		example: '',
		type: 'String',
	},
  {
		key: 'topUpType',
		description: '充值方式',
		example: '00',
		enum: ENUMS.topUpTypeEnum,
		type: 'String',
	},
];