<template>
	<div class="home-page" :style="{ 'background-image': `url('/images/bg.jpeg')` }">
		<div class="ctn">
			<div
				class="logo"
				:style="{ 'background-image': `url('/images/bongloy_logo_light.png')` }"></div>
			<InfoAlert
				type="error"
				:text="this.error"
				v-if="this.error && isShowAlert"
				@closeAlert="handleAlert" />
			<div class="input-field">
				<div class="input-field-icon">
					<InstituteIcon />
				</div>
				<input
					type="text"
					v-model="institute"
					v-on:keyup.enter="submit"
					placeholder="Please Enter Your Institute's Name"
					required />
				<div class="btn" @click="submit">Submit</div>
			</div>
		</div>
	</div>
</template>

<script>
import InfoAlert from '@/components/client/InfoAlert.vue';
import InstituteIcon from '@/components/icons/InsitituteIcon.vue';
import { INSTITUTE_LIST } from '@/const/index';

export default {
	name: 'HomePage',
	components: {
		InfoAlert,
		InstituteIcon,
	},
	data() {
		return {
			institute: '',
			error: '',
			isShowAlert: false,
		};
	},
	computed: {
		institutes() {
			return INSTITUTE_LIST;
		},
	},
	methods: {
		submit() {
			const institutes = Object.keys(this.institutes);

			if (!institutes.includes(this.institute)) {
				this.showError();
				return;
			}

			this.$router.push({ path: `/${this.institute}` });
		},
		showError() {
			this.isShowAlert = true;

			if (this.institute == '') {
				this.error = `Institute can not be empty!`;
				return;
			}

			this.error = `"${this.institute}" is not a valid institute!`;
		},
		handleAlert() {
			this.isShowAlert = false;
		},
	},
};
</script>
