export const REQUEST_JSON_SCAFFOLD = {
	bizServiceId: '',
	data: {},
	orgId: '9022280001',
	reqIP: '',
	sign: 'rHF3aKdmdk3Zd0ePSTC+FPXgh0/QHszwNBKQvVI3NyG1Oo40LNr5atYbX5ubjlxrCsGwHUVh+Tv87tl02ff99fJbmDleTBcNgLRO0PWK+lafFHI/CNupLOq0PDVpJytiM0RIqkbq7uaQ/8fULKUIUAbGGqHV70evvFaqg2P5zCw=',
	timestamp: '1649662285',
	traceId: 'AD33C22887B94464B6ECB5F06C40CBE4',
	verNo: '1.0.0',
};

export const RESPONSE_JSON_SCAFFOLD = {
	data: {},
	orgId: '9022280001',
	respCode: '0',
	respMsg: '成功',
	sign: 'rHF3aKdmdk3Zd0ePSTC+FPXgh0/QHszwNBKQvVI3NyG1Oo40LNr5atYbX5ubjlxrCsGwHUVh+Tv87tl02ff99fJbmDleTBcNgLRO0PWK+lafFHI/CNupLOq0PDVpJytiM0RIqkbq7uaQ/8fULKUIUAbGGqHV70evvFaqg2P5zCw=',
	timestamp: '1649662285',
	traceId: 'AD33C22887B94464B6ECB5F06C40CBE4',
	verNo: '1.0.0',
};
