export const VALUES = {
	preauthorizationStatusValue: ['Success', 'Cancelled', 'Reversal', 'Cancelled Reversal'],
	transactionCategoryValue: ['Transfer accounts'],
	transactionTypeValue: [
		'TRANSFER_IN',
		'TOP_UP',
		'PURCHASE',
		'WITHDRAWAL',
		'PREAUTHORIZATION',
		'PREAUTHORIZATION_REQUESTED',
		'REFUND',
		'CARD_PRINTING_PROGRESS',
		'BAKONG_INCOMING',
		'BAKONG_OUTGOING',
		'BLOCKED_TRANSACTION',
		'ATM_CHECK_BALANCE',
		'UMPS',
	],
	operationTypeValue: ['FREEZE_CARD', 'UNFREEZE_CARD', 'CARD_CONFIGURATION'],
	publicOperationsValue: [
		'DESKTOP_LINK_CBS_ACCOUNT',
		'MOBILE_LINK_CBS_ACCOUNT',
		'MOBILE_DEPOSIT',
		'DESKTOP_DEPOSIT',
	],
};
