export const MOBILE = [
	{
		key: 'mobile',
		description: 'Phone Number (Maximum Length 30, Format: Area Code + "-" + Phone Number)',
		example: '855-23567567',
		type: 'String',
	},
	{
		key: 'mobileOUT',
		description:
			'Sender\'s Phone Number (Maximum Length 30, Format: Area Code + "-" + Phone Number)',
		example: '855-23567567',
		type: 'String',
	},
	{
		key: 'mobileIN',
		description:
			'Receiver\'s Phone Number (Maximum Length 30, Format: Area Code + "-" + Phone Number)',
		example: '855-23567567',
		type: 'String',
	},
	{
		key: 'tel',
		description: 'Telephone',
		example: '',
		type: 'String',
	},
	{
		key: 'phone',
		description: 'Phone Number (Maximum Length 30, Format: Area Code + "-" + Phone Number)',
		example: '855-23567567',
		type: 'String',
	},
];
