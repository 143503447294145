<template>
	<div class="client-layout">
		<SideBarComponent v-if="isSidebarActive" @keyPressed="handleKeyPressed" />
		<router-view
			:class="`${isSidebarActive ? 'client-layout-content-open' : 'client-layout-content-close'}`"
			@toggleSidebar="handleSidebar"></router-view>
		<JumpToSearchBoxDialog v-if="isDialogVisible" />
	</div>
</template>

<script>
import SideBarComponent from '@/components/client/SideBarComponent.vue';
import JumpToSearchBoxDialog from '@/components/client/JumpToSearchBoxDialog.vue';

export default {
	name: 'ClientLayout',
	components: {
		SideBarComponent,
		JumpToSearchBoxDialog,
	},
	data() {
		return {
			isSidebarActive: true,
			onKeyPressed: false,
			isDialogVisible: false,
		};
	},
	methods: {
		handleSidebar(value) {
			if (value != null) {
				this.isSidebarActive = !this.isSidebarActive;
			}
		},
		handleKeyPressed(value) {
			this.onKeyPressed = value;

			if (!this.isSearchInputFocus) {
				this.isDialogVisible = true;

				setTimeout(() => {
					this.isDialogVisible = false;
					this.onKeyPressed = false;
				}, 2000);
			}
		},
	},
};
</script>

<style lang="scss">
@import '@/assets/stylesheets/client/layouts/client-layout.scss';
</style>
