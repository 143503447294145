import { ENUMS } from '../enums';

export const OTHERS = [
	{
		key: 'zip',
		description: '邮编 [长度最大20]',
		example: '12405',
		type: 'String',
	},
	{
		key: 'country',
		description: '国家/地区/组织',
		example: '',
		type: 'String',
	},
	{
		key: 'countryCode',
		description: '商家国家代码',
		example: '',
		type: 'String',
	},
	{
		key: 'verCode',
		description: '验证码 [长度为6]',
		example: '',
		type: 'String',
	},
	{
		key: 'metaData',
		description: 'metaData',
		example: '',
		type: 'String',
	},
	{
		key: 'id',
		description: '交易ID',
		example: '',
		type: 'String',
	},
	{
		key: 'rechargeId',
		description: '充值工单号',
		example: '',
		type: 'String',
	},
	{
		key: 'totFee',
		description: '总手续费',
		example: '',
		type: 'String',
	},
	{
		key: 'exchangeRateFee',
		description: '汇率手续费',
		example: '',
		type: 'String',
	},
	{
		key: 'amount',
		description: '金额',
		example: '',
		type: 'String',
	},
	{
		key: 'originalAmount',
		description: '原始金额',
		example: '',
		type: 'String',
	},
	{
		key: 'fee',
		description: '手续费',
		example: '',
		type: 'String',
	},
	{
		key: 'feeCurrency',
		description: '手续费币种',
		example: '840',
		type: 'String',
	},
	{
		key: 'settledAmt',
		description: '银联结算金额',
		example: '',
		type: 'String',
	},
	{
		key: 'settledWithCustomerAmt',
		description: '结算金额含手续费和汇率手续费',
		example: '',
		type: 'String',
	},
	{
		key: 'settledWithCustomerAmount',
		description: '结算金额含手续费和汇率手续费',
		example: '',
		type: 'String',
	},
	{
		key: 'settledWithCustomerCurrency',
		description: '结算币种',
		example: '840',
		type: 'String',
	},
	{
		key: 'frozen',
		description: '账户是否冻结?',
		example: '',
		type: 'Boolean',
	},
	{
		key: 'crc',
		description: '校验和',
		example: '',
		type: 'String',
	},
	{
		key: 'timestamp',
		description: '时间戳',
		example: '',
		type: 'String',
	},
	{
		key: 'isValid',
		description: '有效吗?',
		example: 'true',
		type: 'Boolean',
	},
	{
		key: 'rejectionReason',
		description: '拒绝原因，如果支付状态是拒绝，必填',
		example: '',
		type: 'String',
	},
	{
		key: 'businessIndication',
		description: '业务指示',
		example: '06',
		enum: ENUMS.businessIndicationEnum,
		type: 'String',
	},
	{
		key: 'fundSource',
		description: '商业指示',
		example: '0',
		enum: ENUMS.fundSourceEnum,
		type: 'String',
	},
	{
		key: 'origResponseCode',
		description: '',
		example: '',
		type: 'String',
	},
	{
		key: 'origResponseMsg',
		description: '',
		example: '',
		type: 'String',
	},
	{
		key: 'numCode',
		description: '数字代码 [数字, 长度3位]',
		example: '156',
		type: 'String',
	},
	{
		key: 'withdrawAmount',
		description: '提取金额',
		example: '',
		type: 'String',
	},
	{
		key: 'refundTransactionId',
		description: '退款交易ID',
		example: '',
		type: 'String',
	},
	{
		key: 'rootCause',
		description: '根本原因',
		example: '',
		enum: ENUMS.rootCauseEnum,
		type: 'String',
	},
	{
		key: 'orderNo',
		description: '订单号 [最大64位]',
		example: '',
		type: 'String',
	},
	{
		key: 'dstCurrency',
		description: '目标汇率币种',
		example: '',
		type: 'String',
	},
	{
		key: 'srcCurrency',
		description: '源汇率币种',
		example: '',
		type: 'String',
	},
	{
		key: 'buyRate',
		description: '买入汇率',
		example: '',
		type: 'String',
	},
	{
		key: 'sellRate',
		description: '卖出汇率',
		example: '',
		type: 'String',
	},
	{
		key: 'totNum',
		description: '申请卡数量 [默认为1]',
		example: '',
		type: 'String',
	},
	{
		key: 'referralCode',
		description: '开卡引导方编码',
		example: '',
		type: 'String',
	},
	{
		key: 'proRegNum',
		description: '补卡流水号',
		example: '',
		type: 'String',
	},
	{
		key: 'mappingId',
		description: '映射客户ID',
		example: '',
		type: 'String',
	},
	{
		key: 'billNumber',
		description: '账单号',
		example: '',
		type: 'String',
	},
	{
		key: 'location',
		description: '位置',
		example: '',
		type: 'String',
	},
	{
		key: 'storeLabel',
		description: '商店标签',
		example: '',
		type: 'String',
	},
	{
		key: 'terminalLabel',
		description: '终端标签',
		example: '',
		type: 'String',
	},
	{
		key: 'userId',
		description: '用户ID',
		example: '',
		type: 'String',
	},
	{
		key: 'customerCurrency',
		description: '客户的账户币种或者卡币种',
		example: '',
		type: 'String',
	},
	{
		key: 'region',
		description: '区域',
		example: '',
		enum: ENUMS.regionEnum,
		type: 'String',
	},
];
