import { ENUMS } from '../enums';

export const ACCOUNT = [
	{
		key: 'acctId',
		description: '账户号',
		example: '123123123',
		type: 'String',
	},
	{
		key: 'accountId',
		description: '账户号',
		example: '123123123',
		type: 'String',
	},
	{
		key: 'acctIdIN',
		description: '转入账号ID',
		example: '123123123',
		type: 'String',
	},
	{
		key: 'acctIdOUT',
		description: '转出账号ID',
		example: '123123123',
		type: 'String',
	},
	{
		key: 'acctNm',
		description: '账户名',
		example: '',
		type: 'String',
	},
	{
		key: 'accountName',
		description: '账户名',
		example: '',
		type: 'String',
	},
	{
		key: 'acctNmIN',
		description: '转入账号姓名',
		example: '',
		type: 'String',
	},
	{
		key: 'acctNmOUT',
		description: '转出账号姓名',
		example: '',
		type: 'String',
	},
	{
		key: 'accountInformation',
		description: '账户号',
		example: '123123123',
		type: 'String',
	},
	{
		key: 'accountQueryInfoModelList',
		description: '账号表',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctId',
			'acctSta',
			'currAvailAt',
			'currBalAt',
			'defFlag',
			'lastTxnDt',
			'lockAt',
			'numOfBoundCards',
			'prdtNo',
		],
	},
	{
		key: 'account',
		description: '账号信息',
		example: '123123123',
		type: 'String',
	},
	{
		key: 'account_currency',
		description: '账户币种',
		example: 'USD',
		type: 'String',
	},
	{
		key: 'account_name',
		description: '账户名称',
		example: '',
		type: 'String',
	},
	{
		key: 'account_type',
		description: '账户类型',
		example: '01',
		type: 'String',
	},
	{
		key: 'bank_code',
		description: '银行编码',
		example: 'BONGKHPP',
		type: 'String',
	},
	{
		key: 'defFlag',
		description: '是否默认账号',
		example: '',
		enum: ENUMS.yesNoEnum,
		type: 'String',
	},
	{
		key: 'numOfBoundCards',
		description: '绑定卡数',
		example: '1',
		type: 'String',
	},
	{
		key: 'extCustId',
		description: '客户映射ID',
		example: '',
		type: 'String',
	},
];
