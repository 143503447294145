<template>
	<div class="endpoint-component" :id="endpoint.id" :ref="endpoint.id">
		<h2 class="endpoint-title">{{ endpoint.title }}</h2>

		<div :class="`endpoint ${endpoint.method}`">
			<div :class="`method ${endpoint.method}`">{{ endpoint.method }}</div>
			<p>{{ endpoint.endpoint }}</p>
		</div>

		<!-- ================== NOTE ================== -->
		<div class="note" v-if="endpoint.note != null && endpoint.noteCN != null">
			<p>
				<span class="title">{{ currentLanguage == 'en' ? 'Note:' : '音符:' }}</span
				>{{ currentLanguage == 'en' ? endpoint.note : currentLanguage == 'cn' && endpoint.noteCN }}
			</p>
		</div>

		<!-- ================== REQUEST ================== -->
		<EndpointContent
			contentType="request"
			title="Request Parameters"
			titleCN="请求参数"
			exampleTitle="Request Example"
			exampleTitleCN="请求示例"
			:endpoint="endpoint"
			:endpointAttri="endpoint.attri"
			:includeAttri="includeReqAttri"
			:currentLanguage="currentLanguage" />

		<!-- ================== RESPONSE ================== -->
		<EndpointContent
			contentType="response"
			title="Response Parameters"
			titleCN="响应参数"
			exampleTitle="Response Example"
			exampleTitleCN="响应示例"
			:endpoint="endpoint"
			:endpointAttri="endpoint.respAttri"
			:includeAttri="includeResAttri"
			:currentLanguage="currentLanguage" />
	</div>
</template>

<script>
import EndpointContent from './EndpointContent.vue';
import {
	INCLUDE_REQUEST_ATTRIBUTES,
	INCLUDE_RESPONSE_ATTRIBUTES,
} from '@/resources/v0/include-attributes';

export default {
	name: 'EndpointComponent',
	props: {
		endpoint: {
			type: Object,
			required: true,
			default: () => {},
		},
		currentLanguage: {
			type: String,
			required: true,
			default: 'en',
		},
	},
	components: {
		EndpointContent,
	},
	data() {
		return {
			includeReqAttri: INCLUDE_REQUEST_ATTRIBUTES,
			includeResAttri: INCLUDE_RESPONSE_ATTRIBUTES,
		};
	},
};
</script>
