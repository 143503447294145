export const TERMINAL = [
	{
		key: 'terminalId',
		description: 'ATM/POS ID',
		example: '',
		type: 'String',
	},
	{
		key: 'terminalLocation',
		description: 'ATM/POS Address',
		example: '',
		type: 'String',
	},
  {
		key: 'terminalNm',
		description: 'Terminal Name',
		example: '',
		type: 'String',
	},
];
