import { ENUMS } from '../enums';
import { VALUES } from '../values';

export const BAKONG = [
	{
		key: 'assetId',
		description: '币种 [USD/KHR]',
		example: 'KHR',
		type: 'String',
	},
	{
		key: 'txnHash',
		description: 'Bakong交易哈希',
		example: '',
		type: 'String',
	},
	{
		key: 'shortHash',
		description: '短Bakong交易哈希 [txnHash前八位]',
		example: '',
		type: 'String',
	},
	{
		key: 'transactionHash',
		description: 'Bakong交易哈希',
		example: '',
		type: 'String',
	},
	{
		key: 'destAccountId',
		description: '转入Bakong ID',
		example: '',
		type: 'String',
	},
	{
		key: 'destAccountName',
		description: '转入Bakong账户名字',
		example: '',
		type: 'String',
	},
	{
		key: 'destBankCode',
		description: '转入银行编码',
		example: '',
		type: 'String',
	},
	{
		key: 'destBankName',
		description: '转入Bakong银行名字',
		example: '',
		type: 'String',
	},
	{
		key: 'bakongType',
		description: 'Bakong类型',
		example: '',
		enum: ENUMS.bakongTypeEnum,
		type: 'String',
	},
	{
		key: 'bankName',
		description: '银行名称',
		example: '',
		type: 'String',
	},
	{
		key: 'participantCode',
		description: '银行代码',
		example: '',
		type: 'String',
	},
	{
		key: 'acquiringBank',
		description: '收单行',
		example: 'Bongloy Payments PLC',
		type: 'String',
	},
	{
		key: 'bakongAccountID',
		description: 'Bakong账户号',
		example: '',
		type: 'String',
	},
	{
		key: 'bakongAcctId',
		description: 'Bakong账户号',
		example: 'username@bong',
		type: 'String',
	},
	{
		key: 'bankCodeOUT',
		description: '转出银行编码',
		example: '',
		type: 'String',
	},
	{
		key: 'bankNmOUT',
		description: '转出银行名字',
		example: '',
		type: 'String',
	},
	{
		key: 'destAcct',
		description: '转入Bakong ID',
		example: '',
		type: 'String',
	},
	{
		key: 'destAcctNm',
		description: '转入Bakong账户名字',
		example: '',
		type: 'String',
	},
	{
		key: 'destBankNm',
		description: '转入Bakong银行名字',
		example: '',
		type: 'String',
	},
	{
		key: 'sourceAcct',
		description: '转出Bakong账号ID',
		example: '',
		type: 'String',
	},
	{
		key: 'sourceAcctNm',
		description: '转出Bakong账户名字',
		example: '',
		type: 'String',
	},
	{
		key: 'sourceBankCode',
		description: '转出银行编码',
		example: '',
		type: 'String',
	},
	{
		key: 'sourceBankNm',
		description: '转出Bakong银行名字',
		example: '',
		type: 'String',
	},
	{
		key: 'srcBankName',
		description: '转出Bakong银行名字',
		example: '',
		type: 'String',
	},
	{
		key: 'bankList',
		description: 'Bank List',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: ['accountId', 'bin', 'logoUuid', 'participantCode', 'publicOperations'],
	},
	{
		key: 'bin',
		description: '银行名字',
		example: '',
		type: 'String',
	},
	{
		key: 'logoUuid',
		description: '商标UUID',
		example: '',
		type: 'String',
	},
	{
		key: 'publicOperations',
		description: '公共运营',
		example: '',
		value: VALUES.publicOperationsValue,
		type: 'Array',
		attributes: [],
	},
	{
		key: 'bkupiFee',
		description: 'Bakong UPI Fee',
		example: '',
		type: 'String',
	},
	{
		key: 'bkupiFeeCurrency',
		description: 'Bakong UPI Fee Currency',
		example: '',
		type: 'String',
	},
	{
		key: 'bkUpiFee',
		description: 'Bakong UPI Fee',
		example: '',
		type: 'String',
	},
	{
		key: 'bkUpiFeeCurrency',
		description: 'Bakong UPI Fee Currency',
		example: '',
		type: 'String',
	},
	{
		key: 'settleWithBakongAmt',
		description: 'Bakong结算金额',
		example: '',
		type: 'String',
	},
	{
		key: 'settleWithBakongCurrency',
		description: 'Bakong结算币种',
		example: '',
		type: 'String',
	},
	{
		key: 'bkupiRate',
		description: 'Bakong UPI Rate',
		example: '',
		type: 'String',
	},
	{
		key: 'bkupiRateAsOfDate',
		description: 'Bakong Rate as of Date',
		example: '',
		type: 'String',
	},
	{
		key: 'bkupiRateCurrency',
		description: 'Bakong UPI Rate Currency',
		example: '',
		type: 'String',
	},
];
