<template>
	<img src="/images/money_express.png" :width="`${width}`" height="36" />
</template>

<script>
export default {
	name: 'MoneyExpressIcon',
	props: {
		width: {
			type: String,
			default: '26',
		},
	},
};
</script>
