import { ENUMS } from '../enums';

export const CARD = [
	{
		key: 'extCardId',
		description: '卡映射ID [每个卡都是独特]',
		example: 'bdfb8456-aada-4625-a8a8-600a0d3bdb3f',
		type: 'String',
	},
	{
		key: 'extCardIds',
		description: 'Card Mapping ID (Unique to Every Card)',
		example: '',
		type: 'Array',
		attributes: [],
	},
	{
		key: 'extCardIdIN',
		description: '转入卡映射ID [每个卡都是独特]',
		example: 'bdfb8456-aada-4625-a8a8-600a0d3bdb3f',
		type: 'String',
	},
	{
		key: 'extCardIdOUT',
		description: '转出卡映射ID [每个卡都是独特]',
		example: 'bdfb8456-aada-4625-a8a8-600a0d3bdb3f',
		type: 'String',
	},
	{
		key: 'brandId',
		description: '品牌ID [长度4位]',
		example: '0002',
		enum: ENUMS.brandIdEnum,
		type: 'String',
	},
	{
		key: 'cardIdEnc',
		description: '卡号',
		encrypted: true,
		example:
			'OGW2IOdpG/Gx2+Z7WVlWRYOENkpwskrZVJyAV1UxIGLS1ENm68+mCjAvQC5sAlB5aVmpMgCmaSIJM2TJVOM1MxGwX5+shGVUIS9+5JGuu4rFQ4d8zCCs2NxZHd7zZ6igKIhpCLS0s6vSijgwNcbZ0gtsbSfCvdzSGDR7TCcFgHFhZTHdOpQGuULRK+oY4MppJJWp9TI3hRbhkfJSnKcdi+cO4N970Sy+6N6CcBLYhrW4Nd8GLoq2Zgvcn/iZjXEMghHBgz91JvV/G3mmMjvMXZTSryCrRvENpX7FUBkk2lrk9H7vp8WjOuTlsxbvuu7U78iS6oP7Ahuar2I+I1b3jA==',
		type: 'String',
	},
	{
		key: 'cardIdEncIN',
		description: '转入卡号',
		encrypted: true,
		example:
			'OGW2IOdpG/Gx2+Z7WVlWRYOENkpwskrZVJyAV1UxIGLS1ENm68+mCjAvQC5sAlB5aVmpMgCmaSIJM2TJVOM1MxGwX5+shGVUIS9+5JGuu4rFQ4d8zCCs2NxZHd7zZ6igKIhpCLS0s6vSijgwNcbZ0gtsbSfCvdzSGDR7TCcFgHFhZTHdOpQGuULRK+oY4MppJJWp9TI3hRbhkfJSnKcdi+cO4N970Sy+6N6CcBLYhrW4Nd8GLoq2Zgvcn/iZjXEMghHBgz91JvV/G3mmMjvMXZTSryCrRvENpX7FUBkk2lrk9H7vp8WjOuTlsxbvuu7U78iS6oP7Ahuar2I+I1b3jA==',
		type: 'String',
	},
	{
		key: 'cardId',
		description: '卡号',
		example: '2345234523452345',
		type: 'String',
	},
	{
		key: 'cardIdIN',
		description: '转入卡号',
		example: '2345234523452345',
		type: 'String',
	},
	{
		key: 'cardIdOUT',
		description: '转出卡号',
		example: '2345234523452345',
		type: 'String',
	},
	{
		key: 'cardId4',
		description: '卡号后4位数',
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardLast4',
		description: '卡号后4位数',
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardIdIN4',
		description: '转入卡号后4',
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardIdOUT4',
		description: '转出卡号后4',
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardId4IN',
		description: '转入卡号后4',
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardId4OUT',
		description: '转出卡号后4',
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardLast4IN',
		description: '转入卡号后4',
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardLast4OUT',
		description: '转出卡号后4',
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardBrhId',
		description: '卡所属机构',
		example: '',
		type: 'String',
	},
	{
		key: 'cardBrhIdIN',
		description: '转入发卡机构号',
		example: '',
		type: 'String',
	},
	{
		key: 'cardBrhIdOUT',
		description: '转出发卡机构号',
		example: '',
		type: 'String',
	},
	{
		key: 'cardBrhNm',
		description: '卡所属机构名称',
		example: '',
		type: 'String',
	},
	{
		key: 'cardBrhNmIN',
		description: '转入卡机构名称',
		example: '',
		type: 'String',
	},
	{
		key: 'cardBrhNmOUT',
		description: '转出卡机构名称',
		example: '',
		type: 'String',
	},
	{
		key: 'type',
		description: '卡类型',
		example: '1',
		enum: ENUMS.cardTypeEnum,
		type: 'String',
	},
	{
		key: 'cvn2',
		description: '卡CVV [3 位数]',
		example: '789',
		type: 'String',
	},
	{
		key: 'cvn2Enc',
		description: '卡CVV [3 位数]',
		encrypted: true,
		example: '789',
		type: 'String',
	},
	{
		key: 'virtuAppId',
		description: '虚拟卡申请流水ID',
		example: '',
		type: 'String',
	},
	{
		key: 'vouId',
		description: '虚拟卡号 [16 位数]',
		example: '2345234523452345',
		type: 'String',
	},
	{
		key: 'physicalAppId',
		description: '实体卡申请单流水号 [最长32]',
		example: '',
		type: 'String',
	},
	{
		key: 'appId',
		description: '申请编号 [实体/虚拟]',
		example: '',
		type: 'String',
	},
	{
		key: 'applicationId',
		description: '实体卡申请ID [最长32位]',
		example: '',
		type: 'String',
	},
	{
		key: 'applicationProgress',
		description: '实体卡印刷进度',
		example: '',
		enum: ENUMS.physicalAppProgessEnum,
		type: 'String',
	},
	{
		key: 'cardBatchQueryInfoModelList',
		description: '卡表',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctId',
			'acctSta',
			'brandId',
			'cardId4',
			'cardSta',
			'currAvailAt',
			'currBalAt',
			'defFlag',
			'endDt',
			'extCardId',
			'lastTxnDt',
			'lockAt',
			'nameEnc',
			'prdtNo',
			'referralCode',
			'startDt',
			'upiState',
		],
	},
	{
		key: 'cardTxnLogListList',
		description: '交易清单',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctId',
			'acctIdIN',
			'acctIdOUT',
			'acctNmIN',
			'acctNmOUT',
			'availAt',
			'bakongType',
			'cardBrhNmIN',
			'cardBrhNmOUT',
			'cardIdEnc',
			'cardIdIN',
			'cardIdOUT',
			'cashflowType',
			'currBalAt',
			'custId',
			'exchangeRateFee',
			'extCardId',
			'extCardIdIN',
			'extCardIdOUT',
			'externalTxnRefId',
			'fee',
			'feeCurrency',
			'id',
			'instituteIdIN',
			'instituteIdOUT',
			'instituteNmIN',
			'instituteNmOUT',
			'merType',
			'merchantId',
			'merchantNm',
			'note',
			'operatorId',
			'operatorNm',
			'originalTxnAmt',
			'originalTxnCurrency',
			'qrCode',
			// 'settledAmt',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'terminalId',
			'terminalLocation',
			'txnAt',
			'txnCategory',
			'txnDt',
			'txnHash',
			'txnRefId',
			'txnSeqId',
			'txnStaCd',
			'txnTm',
			'txnType',
		],
	},
	{
		key: 'cardQueryInfoModelList',
		description: '单卡信息查询',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctId',
			'acctSta',
			'currAvailAt',
			'currBalAt',
			'defFlag',
			'lastTxnDt',
			'lockAt',
			'prdtNo',
		],
	},
	{
		key: 'currCode',
		description: '货币代码 [大写字母, 长度3位]',
		example: 'CNY',
		type: 'String',
	},
	{
		key: 'currName',
		description: '货币名称',
		example: '',
		type: 'String',
	},
	{
		key: 'currAvailAtOUT',
		description: '转出卡可用余额',
		example: '10000',
		type: 'String',
	},
	{
		key: 'currBalAtOUT',
		description: '转出卡账户余额',
		example: '10000',
		type: 'String',
	},
	{
		key: 'currAvailAt',
		description: '可用余额',
		example: '10000',
		type: 'String',
	},
	{
		key: 'currBalAt',
		description: '账户余额',
		example: '10000',
		type: 'String',
	},
	{
		key: 'availAt',
		description: '可用余额',
		example: '10000',
		type: 'String',
	},
	{
		key: 'lockAt',
		description: '冻结余额',
		example: '100',
		type: 'String',
	},
	{
		key: 'lockAtOUT',
		description: '转出卡冻结余额',
		example: '100',
		type: 'String',
	},
	{
		key: 'cashflowType',
		description: '现金周转类型',
		example: '1',
		enum: ENUMS.cashflowTypeEnum,
		type: 'String',
	},
	{
		key: 'topUpTxnLogList',
		description: '充值明细集合',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctIdIN',
			'acctNmIN',
			'cardIdEncIN',
			'cashflowType',
			'externalTxnRefId',
			'id',
			'instituteIdIN',
			'instituteIdOUT',
			'instituteNmIN',
			'instituteNmOUT',
			'note',
			'operatorId',
			'operatorNm',
			'originalTxnAmt',
			'originalTxnCurrency',
			'settledWithCustomerAmt',
			'txnDt',
			'txnRefId',
			'txnSeqId',
			'txnStaCd',
			'txnTm',
		],
	},
	{
		key: 'anonCardList',
		description: '匿名卡集合',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'activatedDate',
			'cardIdEnc',
			'cardSta',
			'currAvailAt',
			'currBalAt',
			'enabledDate',
			'endDt',
			'extCardId',
			'fundsExpiryDate',
			'prdtNo',
			'serialNum',
			'startDt',
		],
	},
	{
		key: 'activatedDate',
		description: '激活时间',
		example: '',
		type: 'String',
	},
	{
		key: 'enabledDate',
		description: '启用时间',
		example: '',
		type: 'String',
	},
	{
		key: 'serialNum',
		description: '卡序列号',
		example: '',
		type: 'String',
	},
	{
		key: 'fundsExpiryDate',
		description: '资金到期日期',
		example: '',
		type: 'String',
	},
	{
		key: 'cardList',
		description: '申请流水ID',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: ['cardNoEnc'],
	},
	{
		key: 'cardNoEnc',
		description: '卡号',
		encrypted: true,
		example:
			'OGW2IOdpG/Gx2+Z7WVlWRYOENkpwskrZVJyAV1UxIGLS1ENm68+mCjAvQC5sAlB5aVmpMgCmaSIJM2TJVOM1MxGwX5+shGVUIS9+5JGuu4rFQ4d8zCCs2NxZHd7zZ6igKIhpCLS0s6vSijgwNcbZ0gtsbSfCvdzSGDR7TCcFgHFhZTHdOpQGuULRK+oY4MppJJWp9TI3hRbhkfJSnKcdi+cO4N970Sy+6N6CcBLYhrW4Nd8GLoq2Zgvcn/iZjXEMghHBgz91JvV/G3mmMjvMXZTSryCrRvENpX7FUBkk2lrk9H7vp8WjOuTlsxbvuu7U78iS6oP7Ahuar2I+I1b3jA==',
		type: 'String',
	},
	{
		key: 'cardTxnLogListList ',
		description: '交易明细集合',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctId',
			'acctIdIN',
			'acctIdOUT',
			'acctNmIN',
			'acctNmOUT',
			'bakongType',
			'bkupiFee',
			'bkupiFeeCurrency',
			'cardLast4OUT',
			'cashflowType',
			'extCardIdOUT',
			'externalTxnRefId',
			'id',
			'mappingId',
			'merchantId',
			'merchantLocation',
			'merchantNm',
			'merchantType',
			'settleWithBakongAmt',
			'settleWithBakongCurrency',
			'txnAmt',
			'txnCurrency',
			'txnDt',
			'txnHash',
			'txnRefId',
			'txnStaCd',
			'txnTm',
			'txnType',
		],
	},
	{
		key: 'anonCardTxnList',
		description: '匿名卡交易明细集合',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'availAt',
			'cardId4',
			'currBalAt',
			'exchangeRateFee',
			'fee',
			'feeCurrency',
			'id',
			'instituteNmIN',
			'instituteNmOUT',
			'merType',
			'merchantId',
			'merchantNm',
			'note',
			'originalTxnAmt',
			'originalTxnCurrency',
			'serialNum',
			'settledAmt',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'terminalId',
			'terminalLocation',
			'txnAt',
			'txnCategory',
			'txnDt',
			'txnRefId',
			'txnSeqId',
			'txnStaCd',
			'txnTm',
			'txnType',
		],
	},
];
