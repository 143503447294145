import documentationRouter from './documentation';
import PassThroughComponent from '@/components/PassThroughComponent.vue';
import { INSTITUTE_LIST } from '@/const';
import RedirectPage from '@/pages/RedirectPage.vue';

const instituteRoutes = Object.keys(INSTITUTE_LIST).map((e) => {
	return {
		path: `${e}`,
		name: `${e}-redirect-page`,
		component: RedirectPage,
		meta: {
			redirect: `/docs/v0/${e}`,
			showMenu: false,
		},
	};
});

const clientRouter = [
	...instituteRoutes,
	{
		path: '/docs',
		component: PassThroughComponent,
		children: [
			{
				path: ':version',
				component: PassThroughComponent,
				children: [...documentationRouter],
			},
		],
	},
];

export default clientRouter;
