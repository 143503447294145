import { ENUMS } from '../enums';

export const ACCOUNT = [
	{
		key: 'acctId',
		description: 'Account Number',
		example: '123123123',
		type: 'String',
	},
	{
		key: 'accountId',
		description: 'Account Number',
		example: '123123123',
		type: 'String',
	},
	{
		key: 'acctIdIN',
		description: "Receiver's Account Number",
		example: '123123123',
		type: 'String',
	},
	{
		key: 'acctIdOUT',
		description: "Sender's Account Number",
		example: '123123123',
		type: 'String',
	},
	{
		key: 'acctNm',
		description: 'Account Name',
		example: '',
		type: 'String',
	},
	{
		key: 'accountName',
		description: 'Account Name',
		example: '',
		type: 'String',
	},
	{
		key: 'acctNmIN',
		description: "Receiver's Full Name",
		example: '',
		type: 'String',
	},
	{
		key: 'acctNmOUT',
		description: "Sender's Full Name",
		example: '',
		type: 'String',
	},
	{
		key: 'accountInformation',
		description: 'Account ID',
		example: '123123123',
		type: 'String',
	},
	{
		key: 'accountQueryInfoModelList',
		description: 'List of Accounts',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctId',
			'acctSta',
			'currAvailAt',
			'currBalAt',
			'defFlag',
			'lastTxnDt',
			'lockAt',
			'numOfBoundCards',
			'prdtNo',
		],
	},
	{
		key: 'account',
		description: 'Account Information',
		example: '123123123',
		type: 'String',
	},
	{
		key: 'account_currency',
		description: 'Account Currency',
		example: 'USD',
		type: 'String',
	},
	{
		key: 'account_name',
		description: 'Account Name',
		example: '',
		type: 'String',
	},
	{
		key: 'account_type',
		description: 'Account Type',
		example: '01',
		type: 'String',
	},
	{
		key: 'bank_code',
		description: 'Bank Code',
		example: 'BONGKHPP',
		type: 'String',
	},
	{
		key: 'defFlag',
		description: 'Is it the Default Account',
		example: '',
		enum: ENUMS.yesNoEnum,
		type: 'String',
	},
	{
		key: 'numOfBoundCards',
		description: 'Number of Bound Cards',
		example: '1',
		type: 'String',
	},
	{
		key: 'extCustId',
		description: 'Customer Mapping ID',
		example: '',
		type: 'String',
	},
];
