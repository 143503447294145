export const AETRINO = {
	include: [
		// virtual card
		'1101',
		'1102',
		'1103',

		// physical card

		// verification
		'2101',

		// query cardholder info
		'2221',
		'2231',
		'2232',
		'2233',
		'2234',
		'2241',
		'2242',

		// modify cardholder info
		// card configuration

		// internal transfer
		'4101',
		'4102',

		// bakong transaction
		'4211',
		'4212',
		'4221',
		'4222',
		'4223',
		'4231',
		'4232',
		'4233',
		'4253',

		// umps
		// umts
		// ohters

		// notificaiton
		'NTF1',
		'NTF2',
		'NTF7',
		'NTF9',
		'NTF10',
	],
};
