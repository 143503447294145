<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		class="icon icon-tabler icon-tabler-credit-card"
		width="26"
		height="26"
		viewBox="0 0 24 24"
		stroke-width="2"
		stroke="currentColor"
		fill="none"
		stroke-linecap="round"
		stroke-linejoin="round">
		<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
		<rect x="3" y="5" width="18" height="14" rx="3"></rect>
		<line x1="3" y1="10" x2="21" y2="10"></line>
		<line x1="7" y1="15" x2="7.01" y2="15"></line>
		<line x1="11" y1="15" x2="13" y2="15"></line>
	</svg>
</template>

<script>
export default {
	name: 'CardIcon',
};
</script>
