import { ENUMS } from '../enums';

export const KEY = [
	{
		key: 'keyId',
		description: 'Card/Account Number',
		example: '',
		type: 'String',
	},
	{
		key: 'keyIdIN',
		description: "Receiver's Account/Card Number",
		example: '',
		type: 'String',
	},
	{
		key: 'keyIdINType',
		description: "Receiver's Account/Card",
		example: '1',
		enum: ENUMS.keyIdTypeEnum,
		type: 'String',
	},
	{
		key: 'keyIdOUT',
		description: "Sender's Account/Card Number",
		example: '',
		type: 'String',
	},
];
