import { createRouter, createWebHistory } from 'vue-router';
import clientRouter from './client';
import ClientLayout from '../layouts/ClientLayout.vue';
import HomePage from '@/pages/HomePage.vue';
import RedirectPage from '@/pages/RedirectPage.vue';

const routes = [
	{
		path: '/',
		component: HomePage,
	},
	{
		path: '/',
		component: ClientLayout,
		children: [...clientRouter],
	},
	{
		path: '/:catchAll(.*)',
		component: RedirectPage,
		meta: {
			redirect: '/',
			showMenu: false,
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
