<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
		class="icon icon-tabler icons-tabler-outline icon-tabler-wallet">
		<path stroke="none" d="M0 0h24v24H0z" fill="none" />
		<path
			d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
		<path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
	</svg>
</template>

<script>
export default {
	name: 'WalletIcon',
};
</script>
