<template>
	<img src="/images/unionpay.png" :width="`${width}`" />
</template>

<script>
export default {
	name: 'UnionPayIcon',
	props: {
		width: {
			type: String,
			default: '24',
		},
	},
};
</script>
