import { HTTP_METHODS, TAG } from '@/const';

export const UMPS = [
	{
		id: '4311',
		title: 'Activate UPI Mobile Payment',
		titleCN: '开通UPI移动支付',
		endpoint: '/app/applyInsideToken',
		method: HTTP_METHODS.POST,
		tag: TAG.UMPS,
		attributes: ['custId', 'extCardId'],
		respAttributes: [],
	},
	{
		id: '4312',
		title: 'Query QR Code Info',
		titleCN: '二维码信息查询',
		endpoint: '/app/qrCodeInfoQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.UMPS,
		attributes: ['qrCode'],
		respAttributes: [
			'countryCode',
			'mechantCode',
			'merchantCity',
			'merchantId',
			'merchantNm',
			'qrCode',
			'qrcodeCategory',
			'qrcodeState',
			'txnAmt',
			'txnCurrency',
			'txnID',
		],
	},
	{
		id: '4313',
		title: 'Scan Receiving QR',
		titleCN: '扫描收款码',
		endpoint: '/app/scanReceivingQR',
		method: HTTP_METHODS.POST,
		tag: TAG.UMPS,
		note: 'Need to Call /app/qrCodeInfoQuery First.',
		noteCN: '需先调用/app/qrCodeInfoQuery查询。',
		attributes: ['custId', 'extCardId', 'receivingQR', 'txnAmt', 'txnCurrency', 'txnID'],
		optionalAttributes: ['txnAmt'],
		respAttributes: [
			'acctIdIN',
			'acctIdOUT',
			'acctNmIN',
			'acctNmOUT',
			'cardBrhIdIN',
			'cardBrhIdOUT',
			'cardBrhNmIN',
			'cardBrhNmOUT',
			'cardIdIN4',
			'cardIdOUT4',
			'currAvailAtOUT',
			'currBalAtOUT',
			'intTxnRefId',
			'intTxnSeqId',
			'lockAtOUT',
			'merchantId',
			'merchantNm',
			'note',
			'originalTxnAmt',
			'originalTxnCurrency',
			'qrCode',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'state',
			'txnAt',
			'txnDt',
			'txnID',
			'txnRefId',
			'txnTm',
			'txnType',
		],
	},
	{
		id: '4313B',
		title: 'Pay UnionPay QR',
		titleCN: '扫描收款码',
		endpoint: '/app/payUnionPayQR',
		method: HTTP_METHODS.POST,
		tag: TAG.UMPS,
		note: 'Need to Call /app/qrCodeInfoQuery First.',
		noteCN: '需先调用/app/qrCodeInfoQuery查询。',
		attributes: ['mappingId', 'receivingQR', 'txnAmt', 'txnCurrency', 'txnHash', 'txnID', 'usdAmt'],
		optionalAttributes: ['mappingId', 'txnHash'],
		respAttributes: [
			'merchantId',
			'merchantNm',
			'note',
			'originalTxnAmt',
			'originalTxnCurrency',
			'qrCode',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'state',
			'txnDt',
			'txnID',
			'txnRefId',
			'txnTm',
		],
	},
	// {
	// 	id: '4314',
	// 	title: 'Scan the Payment Code (Domestic Code)',
	// 	titleCN: '扫描收款码 [境内码]',
	// 	endpoint: '/app/mpqrcPaymentUrl',
	// 	method: HTTP_METHODS.POST,
	// 	tag: TAG.UMPS,
	// 	note: 'Need to Call /app/qrCodeInfoQuery First.',
	// 	noteCN: '需先调用/app/qrCodeInfoQuery查询。',
	// 	attributes: ['custId', 'extCardId', 'txnAmt', 'txnCurrency', 'txnID'],
	// 	respAttributes: ['txnID'],
	// },
	{
		id: '4321',
		title: 'Generate Payment QR',
		titleCN: '生成付款码',
		endpoint: '/app/generatePaymentQR',
		method: HTTP_METHODS.POST,
		tag: TAG.UMPS,
		attributes: ['custId', 'extCardId'],
		respAttributes: [
			'barcodeCpqrcPayload',
			'barcodeCpqrcPayloadtxnID',
			'emvCpqrcPayload',
			'emvCpqrcPayloadtxnID',
		],
	},
	{
		id: '4322',
		title: 'Verify Payment Addition',
		titleCN: '付款码交易附加验证',
		endpoint: '/app/verifyPaymentAddition',
		method: HTTP_METHODS.POST,
		tag: TAG.UMPS,
		note: 'The acquirer scans the code and sends it to UnionPay. UnionPay judges that if the amount is small, it will send it directly to the issuing bank. Otherwise, UnionPay will send ADDITIONAL_PROCESSING to the wallet background, and the wallet background update status is additional processing request.',
		noteCN:
			'收单机构扫码发到银联, 银联判断如果小金额, 直接送发卡行, 否则银联发送ADDITIONAL_PROCESSING到钱包后台, 钱包后台更新状态为附加处理请求。',
		attributes: ['custId', 'emvCpqrcPayload', 'extCardId', 'paymentStatus', 'rejectionReason'],
		respAttributes: [],
	},
	{
		id: '4323',
		title: 'Query Payment Status',
		titleCN: '付款码交易查询',
		endpoint: '/app/queryPaymentStatus',
		method: HTTP_METHODS.POST,
		tag: TAG.UMPS,
		attributes: ['custId', 'extCardId', 'txnID'],
		respAttributes: ['originalAmount', 'paymentStatus', 'txnAmt'],
	},
];
