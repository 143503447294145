<template>
	<img src="/images/cardholder_info.png" :width="`${width}`" />
</template>

<script>
export default {
name: 'CardholderInfoIcon',
	props: {
		width: {
			type: String,
			default: '24',
		},
	},
};
</script>
