export const AGENT = {
	include: [
		// virtual card
		'1101',
		'1102',
		'1103',

		// physical card
		'1201',
		'1202',
		'1203',

		// verification
		// query cardholder info
		// modify cardholder info
		// card configuration

		// internal transfer
		'4101',

		// bakong transaction
		// umps
		// umts
		// ohters

		// notificaiton
	],
};
