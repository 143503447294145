import { HTTP_METHODS, TAG } from '@/const';

export const ANONYMOUS_CARD = [
	{
		id: 'N100',
		title: 'Parnter User Verification',
		titleCN: '验证合作用户',
		endpoint: '/app/verifyPartnerUser',
		method: HTTP_METHODS.POST,
		tag: TAG.ANONYMOUS_CARD,
		attributes: ['mobile', 'partnerId', 'pwdEnc', 'userNameEnc'],
		optionalAttributes: [],
		respAttributes: ['email', 'mobile', 'partnerId', 'sex', 'userId', 'userNameEnc'],
	},
	{
		id: 'N101',
		title: 'Query Anonymous Card List',
		titleCN: '查询匿名卡列表',
		endpoint: '/app/queryAnonCardList',
		method: HTTP_METHODS.POST,
		tag: TAG.ANONYMOUS_CARD,
		attributes: ['cardSta', 'pageNum', 'pageSize', 'partnerId', 'userId'],
		optionalAttributes: ['cardSta'],
		respAttributes: ['anonCardList', 'totalCount'],
	},
	{
		id: 'N102',
		title: 'Enable an Anonymous Card',
		titleCN: '启用匿名卡',
		endpoint: '/app/enableAnonCard',
		method: HTTP_METHODS.POST,
		tag: TAG.ANONYMOUS_CARD,
		attributes: ['extCardId', 'note', 'partnerId', 'userId'],
		optionalAttributes: [],
		respAttributes: ['cardId4'],
	},
	{
		id: 'N103',
		title: 'Activate an Anonymous Card',
		titleCN: '激活匿名卡',
		endpoint: '/app/activateAnonCard',
		method: HTTP_METHODS.POST,
		tag: TAG.ANONYMOUS_CARD,
		attributes: ['brandId', 'cardIdEnc', 'cvn2Enc', 'endDt', 'pinEnc'],
		optionalAttributes: [],
		respAttributes: ['cardId4'],
	},
	{
		id: 'N104',
		title: 'Query an Anonymous Card Status',
		titleCN: '查询匿名卡状态',
		endpoint: '/app/queryAnonCardStatus',
		method: HTTP_METHODS.POST,
		tag: TAG.ANONYMOUS_CARD,
		attributes: ['cardIdEnc', 'serialNum'],
		optionalAttributes: [],
		respAttributes: [
			'activatedDate',
			'cardId4',
			'cardSta',
			'enabledDate',
			'endDt',
			'fundsExpiryDate',
			'serialNum',
		],
	},
	{
		id: 'N105',
		title: 'Query an Anonymous Card Info',
		titleCN: '查询匿名卡',
		endpoint: '/app/queryAnonCard',
		method: HTTP_METHODS.POST,
		tag: TAG.ANONYMOUS_CARD,
		attributes: ['cardIdEnc', 'pinEnc'],
		respAttributes: [
			'activatedDate',
			'cardIdEnc',
			'cardSta',
			'currAvailAt',
			'currBalAt',
			'enabledDate',
			'endDt',
			'extCardId',
			'fundsExpiryDate',
			'prdtNo',
			'serialNum',
			'startDt',
		],
	},
	{
		id: 'N106',
		title: "Query Anonymous Card Transactions",
		titleCN: '查询匿名卡交易',
		endpoint: '/app/queryAnonCardTxn',
		method: HTTP_METHODS.POST,
		tag: TAG.ANONYMOUS_CARD,
		attributes: ['cardIdEnc', 'endDt', 'pageNum', 'pageSize', 'pinEnc', 'startDt'],
		optionalAttributes: ['endDt', 'startDt'],
		respAttributes: ['anonCardTxnList', 'totalCount'],
	},
];
