import { ENUMS } from '../enums';

export const PAYMENT = [
	{
		key: 'payeeNameEnc',
		description: 'Payee Name (Maximum Plaintext 30 Bits)',
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payeePanEnc',
		description: 'Payee Pan (Maximum Plaintext 19 Bits)',
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerAccountEnc',
		description: 'Payer Account (Maximum Plaintext 19 Bits)',
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerIdNoEnc',
		description: "Payer's ID Number (Maximum Plaintext 20 Bits)",
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerExtCardId',
		description: "Payer's Card Mapping ID (Unique to Every Card)",
		example: '',
		type: 'String',
	},
	{
		key: 'payerIdType',
		description: "Payer's Document Type",
		example: '',
		enum: ENUMS.documentTypeEnum,
		type: 'String',
	},
	{
		key: 'payerNameEnc',
		description: "Payer's Name (Maximum Plaintext 30 Bits)",
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'payerNationality',
		description: 'Nationality of Payer (Fixed Length 3)',
		example: '',
		type: 'String',
	},
];
