export const TRANSFER = [
	{
		key: 'transferDate',
		description: 'Transfer Date [yyyyMMdd], topUpType=01Required',
		example: '',
		type: 'String',
	},
	{
		key: 'transferNo',
		description: 'Transfer Number, topUpType=01Required (Maximum Length 64)',
		example: '',
		type: 'String',
	},
	{
		key: 'transferTime',
		description: 'Transfer Time [HHmmss], topUpType=01Required',
		example: '',
		type: 'String',
	},
	{
		key: 'transfereeInfo',
		description: 'Payee Account Information, topUpType=01Required (Maximum Length 256)',
		example: '',
		type: 'String',
	},
	{
		key: 'transfererInfo',
		description: 'Payer Account Information, topUpType=01Required (Maximum Length 256)',
		example: '',
		type: 'String',
	},
];
