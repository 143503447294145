import { HTTP_METHODS, TAG } from '@/const';

export const NOTIFICATION = [
	{
		id: 'NTF1',
		title: 'Transfer In',
		titleCN: '转入',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctIdIN',
			'acctIdOUT',
			'acctNmIN',
			'acctNmOUT',
			'amount',
			'cardId4IN',
			'cardId4OUT',
			'custIdIN',
			'custIdOUT',
			'instituteIdIN',
			'instituteIdOUT',
			'instituteNmIN',
			'instituteNmOUT',
			'timestamp',
			'transactionId',
			'transactionType',
		],
	},
	{
		id: 'NTF2',
		title: 'Top Up',
		titleCN: '充值',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctIdIN',
			'acctNmIN',
			'amount',
			'cardId4IN',
			'custIdIN',
			'instituteIdIN',
			'instituteIdOUT',
			'instituteNmIN',
			'instituteNmOUT',
			'operatorId',
			'operatorNm',
			'timestamp',
			'transactionId',
			'transactionType',
		],
	},
	{
		id: 'NTF3',
		title: 'Purchase',
		titleCN: '购买',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctIdOUT',
			'acctNmOUT',
			'amount',
			'cardId4OUT',
			'custIdOUT',
			'instituteIdOUT',
			'instituteNmOUT',
			'merchantId',
			'merchantNm',
			'originalAmount',
			'terminalLocation',
			'terminalNm',
			'timestamp',
			'transactionId',
			'transactionType',
		],
	},
	{
		id: 'NTF4',
		title: 'Withdrawal',
		titleCN: '提款',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctIdOUT',
			'acctNmOUT',
			'amount',
			'cardId4OUT',
			'custIdOUT',
			'fee',
			'instituteIdOUT',
			'instituteNmOUT',
			'originalAmount',
			'terminalId',
			'terminalLocation',
			'terminalNm',
			'timestamp',
			'transactionId',
			'transactionType',
			'withdrawAmount',
		],
	},
	{
		id: 'NTF5',
		title: 'Preauthorization',
		titleCN: '预授权',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctIdOUT',
			'amount',
			'cardId4OUT',
			'custIdOUT',
			'instituteIdOUT',
			'instituteNmOUT',
			'merchantId',
			'merchantNm',
			'status',
			'timestamp',
			'transactionId',
			'transactionType',
		],
	},
	{
		id: 'NTF6',
		title: 'Preauthorization Requested',
		titleCN: '预授权请求',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctIdOUT',
			'acctNmOUT',
			'amount',
			'cardId4OUT',
			'custIdOUT',
			'instituteIdOUT',
			'instituteNmOUT',
			'merchantId',
			'merchantNm',
			'status',
			'timestamp',
			'transactionId',
			'transactionType',
		],
	},
	{
		id: 'NTF7',
		title: 'Refund',
		titleCN: '退款',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctId',
			'amount',
			'cardId4',
			'custId',
			'instituteIdOUT',
			'instituteNmOUT',
			'merchantId',
			'merchantNm',
			'refundTransactionId',
			'timestamp',
			'transactionId',
			'transactionType',
		],
	},
	{
		id: 'NTF8',
		title: 'Card Printing Progress',
		titleCN: '卡片印刷进度',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctId',
			'acctNm',
			'applicationId',
			'applicationProgress',
			'cardId4',
			'custId',
			'extCardId',
			'instituteId',
			'instituteNm',
			'timestamp',
			'transactionType',
		],
	},
	{
		id: 'NTF9',
		title: 'Bakong Incoming',
		titleCN: 'Bakong传入',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctIdIN',
			'acctNmIN',
			'cardId4IN',
			'custIdIN',
			'fee',
			'instituteIdIN',
			'instituteNmIN',
			'originalTransactionAmount',
			'originalTransactionCurrency',
			'settledWithCustomerAmount',
			'sourceAcct',
			'sourceAcctNm',
			'sourceBankCode',
			'sourceBankNm',
			'status',
			'timestamp',
			'transactionId',
			'transactionType',
			'txnRefId',
		],
	},
	{
		id: 'NTF10',
		title: 'Bakong Outgoing',
		titleCN: 'Bakong传出',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctIdOUT',
			'acctNmOUT',
			'bakongType',
			'cardId4OUT',
			'custIdOUT',
			'destAcct',
			'destAcctNm',
			'destBankCode',
			'destBankNm',
			'fee',
			'instituteIdOUT',
			'instituteNmOUT',
			'originalTransactionAmount',
			'originalTransactionCurrency',
			'settledWithCustomerAmount',
			'status',
			'timestamp',
			'transactionHash',
			'transactionId',
			'transactionType',
			'txnRefId',
		],
	},
	{
		id: 'NTF11',
		title: 'Blocked Transaction',
		titleCN: '交易受阻',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctNm',
			'cardId4',
			'custId',
			'fee',
			'instituteId',
			'instituteNm',
			'originalTransactionAmount',
			'originalTransactionCurrency',
			'rootCause',
			'settledWithCustomerAmount',
			'terminalId',
			'terminalLocation',
			'timestamp',
			'transactionCategory',
			'transactionId',
			'transactionType',
		],
	},
	{
		id: 'NTF12',
		title: 'ATM Check Balance',
		titleCN: 'ATM查看余额',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'custId',
			'amount',
			'fee',
			'acctId',
			'acctNm',
			'cardId4OUT',
			'terminalNm',
			'terminalId',
			'terminalLocation',
			'instituteId',
			'instituteNm',
			'transactionId',
			'timestamp',
			'transactionType',
		],
	},
	{
		id: 'NTF13',
		title: 'Freeze Card',
		titleCN: '卡冻结',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctId',
			'acctNm',
			'cardId4',
			'custId',
			'instituteId',
			'instituteNm',
			'operationType',
			'timestamp',
		],
	},
	{
		id: 'NTF14',
		title: 'Unfreeze Card',
		titleCN: '卡解冻',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctId',
			'acctNm',
			'cardId4',
			'custId',
			'instituteId',
			'instituteNm',
			'operationType',
			'timestamp',
		],
	},
	{
		id: 'NTF15',
		title: 'Card Configuration',
		titleCN: '卡配置',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctId',
			'cardId4',
			'curCardStatus',
			'custId',
			'instituteId',
			'instituteNm',
			'operationType',
			'prevCardStatus',
			'timestamp',
		],
	},
	{
		id: 'NTF16',
		title: 'UMPS',
		titleCN: 'UMPS',
		endpoint: 'yourNotificationUrl',
		method: HTTP_METHODS.POST,
		tag: TAG.NOTIFICATION,
		attributes: [],
		respAttributes: [
			'acctIdOUT',
			'acctNmOUT',
			'cardId4OUT',
			'custIdOUT',
			'instituteIdOUT',
			'instituteNmOUT',
			'merchantId',
			'merchantNm',
			'notifyType',
			'originalAmt',
			'originalCurrency',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'timestamp',
			'transactionId',
			'transactionType',
		],
	},
];
