export const TERMINAL = [
	{
		key: 'terminalId',
		description: 'ATM/POS ID',
		example: '',
		type: 'String',
	},
	{
		key: 'terminalLocation',
		description: 'ATM/POS机地址',
		example: '',
		type: 'String',
	},
  {
		key: 'terminalNm',
		description: 'ATM/POS名字',
		example: '',
		type: 'String',
	},
];
