<template>
	<div class="top-bar-component">
		<font-awesome-icon icon="fa-solid fa-bars" size="xl" @click="toggleSidebar" />
		<div class="top-bar-right">
			<div class="language" @click="toggleLanguage">
				<img
					src="@/../public/images/united-kingdom.png"
					class="language-flag"
					v-if="currentLanguage == 'en'" />
				<img
					src="@/../public/images/china.png"
					class="language-flag"
					v-if="currentLanguage == 'cn'" />
				<span class="language-text" :style="`${currentLanguage == 'en' && 'margin-top: 2px'}`">{{
					currentLanguage == 'en' ? 'English' : '简体中文'
				}}</span>
			</div>
			<!-- <div class="institute">
				<font-awesome-icon icon="fa-solid fa-user" class="user" />
				<font-awesome-icon icon="fa-solid fa-caret-down" class="caret-down" />
			</div> -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'TopBarComponent',
	props: {
		currentLanguage: {
			type: String,
			required: true,
			default: 'en',
		},
	},
	computed: {
		lang() {
			return this.$route.query.lang || 'en';
		},
	},
	methods: {
		toggleLanguage() {
			if (this.lang == 'en') {
				this.$router.replace({ path: this.$route.path, query: { lang: 'cn' } });
			} else {
				this.$router.replace({ path: this.$route.path, query: { lang: 'en' } });
			}
		},
		toggleSidebar() {
			this.$emit('toggleSidebar', false);
		},
	},
};
</script>
