import { BAKONG as bakong } from './co-brands/bakong';
import { BRONX as bronx } from './co-brands/bronx';
import { SMILESHOP as smileshop } from './co-brands/smileshop';
// import { MOHANOKOR as mohanokor } from './co-brands/mohanokor';
import { EKYC as ekyc } from './co-brands/ekyc';
import { JIANDANDIAN as jiandandian } from './co-brands/jiandandian';
import { AETRINO as aetrino } from './co-brands/aetrino';
import { AGENT as agent } from './co-brands/agent';

export const INSTITUTE_LIST = {
	bakong,
	bronx,
	smileshop,
	// mohanokor,
	ekyc,
	jiandandian,
	aetrino,
	agent,
	current: {},
};

export const HTTP_METHODS = {
	POST: 'POST',
	GET: 'GET',
};

export const TAG = {
	VIRTUAL_CARD: {
		id: '1100',
		name: 'Virtual Card',
	},
	PHYSICAL_CARD: {
		id: '1200',
		name: 'Physical Card',
	},
	VERIFICATION: {
		id: '2100',
		name: 'Verification',
	},
	QUERY_CARDHOLDER_INFO: {
		id: '2200',
		name: 'Query Cardholder Info',
	},
	MODIFY_CARDHOLDER_INFO: {
		id: '2300',
		name: 'Modify Cardholder Info',
	},
	CARD_CONFIGURATION: {
		id: '3100',
		name: 'Card Configuration',
	},
	ACCOUNT_CONFIGURATION: {
		id: '3200',
		name: 'Account Configuration',
	},
	INTERNAL_TRANSFER: {
		id: '4100',
		name: 'Internal Transfer',
	},
	BAKONG_TRANSACTION: {
		id: '4200',
		name: 'Bakong Transaction',
	},
	UMPS: {
		id: '4300',
		name: 'UMPS',
	},
	UMTS: {
		id: '4400',
		name: 'UMTS',
	},
	ANONYMOUS_CARD: {
		id: 'ANON',
		name: 'Anonymous Card',
	},
	OTHERS: {
		id: '0',
		name: 'Others',
	},
	NOTIFICATION: {
		id: 'NTF',
		name: 'Notification',
	},
};

export const TAG_ORDER = {
	1100: 1,
	1200: 2,
	2100: 3,
	2200: 4,
	2300: 5,
	3100: 6,
	3200: 7,
	4200: 8,
	4300: 9,
	4400: 10,
	0: 11,
	NT: 12,
};
