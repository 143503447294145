export const MERCHANT = [
	{
		key: 'merchantId',
		description: 'Merchant ID',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantID',
		description: 'Merchant ID',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantNm',
		description: "Merchant's Name",
		example: '',
		type: 'String',
	},
	{
		key: 'mechantCode',
		description: 'Merchant Code',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantCity',
		description: 'Merchant City',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantCategoryCode',
		description: 'Merchant Category Code',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantName',
		description: 'Merchant Name',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantType',
		description: 'Merchant Type',
		example: '',
		type: 'String',
	},
	{
		key: 'merType',
		description: 'Merchant Type',
		example: '',
		type: 'String',
	},
	{
		key: 'merchantLocation',
		description: 'Merchant Location',
		example: '',
		type: 'String',
	},
];
