export const MEMBER = [
	{
		key: 'memberId',
		description: '会员ID',
		example: '',
		type: 'String',
	},
	{
		key: 'memberEnrolledDate',
		description: '会员注册日期 [yyyyMMdd]',
		example: '20221231',
		type: 'String',
	},
];
