<template>
	<div class="endpoint-listing-page">
		<TopBarComponent :currentLanguage="currentLanguage" @toggleSidebar="toggleSidebar" />
		<div id="endpoint-scroll-ctn" class="page md-page endpoint-content">
			<EndpointComponent
				v-for="endpoint in endpoints"
				:key="endpoint.endpoint"
				:endpoint="endpoint"
				:currentLanguage="currentLanguage" />
		</div>
		<!-- <ScrollToTopOrBottomComponent /> -->
	</div>
</template>

<script>
import TopBarComponent from '@/components/client/TopBarComponent.vue';
import EndpointComponent from '@/components/client/EndpointComponent.vue';
// import ScrollToTopOrBottomComponent from '@/components/client/ScrollToTopOrBottomComponent.vue';

export default {
	name: 'EndpointListingPage',
	components: {
		TopBarComponent,
		EndpointComponent,
		// ScrollToTopOrBottomComponent,
	},
	computed: {
		endpoints() {
			return this.$store.getters['client/endpoints/filterEndpoints'](
				this.$route.meta.include,
				this.$route.meta.exclude,
				this.$route.query.lang
			);
		},
		currentLanguage() {
			return this.$route.query.lang || 'en';
		},
	},
	methods: {
		toggleSidebar(value) {
			this.$emit('toggleSidebar', value);
		},
	},
	mounted() {
		if (localStorage.getItem('reloaded')) {
			localStorage.removeItem('reloaded');
		} else {
			localStorage.setItem('reloaded', '1');
			this.$router.go();
		}
	},
};
</script>
